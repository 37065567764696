import { useEffect, useState } from 'react';

import { useMinimalAuth } from 'hooks';

import ApiManager from 'ApiManager';

export const STATUSES = { init: 'thumbnail-init', error: 'thumbnail-error' };

const useFileImage = ({ id }) => {
  const user = useMinimalAuth();
  const [thumbnail, setThumbnail] = useState(STATUSES.init);

  useEffect(() => {
    if (id) {
      ApiManager.get(`/v3/path/${id}/file/data`, null, user)
        .then((res) => {
          let reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onloadend = () => {
            setThumbnail(reader.result);
          };
        })
        .catch((err) => {
          setThumbnail(STATUSES.error);
          console.error(err);
        });
    }
  }, [id, user]);

  return id && thumbnail;
};

export default useFileImage;
