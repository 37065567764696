import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  formSide: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paperContainer: {
    width: '80%',
    maxWidth: '430px',
    marginTop: '5vh',
    height: 'fit-content',
    padding: '32px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('lg')]: {
      marginTop: '5vh',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '50px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
  },
  title: {
    marginBottom: '20px',
    marginTop: '10px',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '12px',
    },
  },
  textFieldContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: theme.spacing(2.5, 0),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0),
    },
  },
  textFieldLabel: {
    marginBottom: '6px',
  },
  textField: {
    width: '100%',
  },
  formHelperText: {
    margin: '0 14px',
  },
  submitButton: {
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  backButton: {
    width: '100%',
    marginTop: '-10px',
  },
  checkboxLabel: {},
  termsErrorText: {
    color: theme.palette.error.main,
    marginLeft: '32px',
  },
  loginLinkContainer: {
    color: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    margin: theme.spacing(2, 0),
    gap: theme.spacing(1),
  },
  link: {},
  checkboxLink: {},
  errorContainer: {
    width: '100%',
    borderRadius: '6px',
    border: `1px solid ${theme.palette.error.dark}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    marginTop: '10px ',
    marginBottom: '10px ',
    boxSizing: 'border-box',
  },
  errorText: {
    color: theme.palette.error.dark,
  },
  popoverMenu: {
    zIndex: '10000 !important',
  },
}));

export default useStyles;
