import React from 'react';

import { Paper, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

import FullScreenLayout from '../../FullScreenLayout';
import useStyles from './useFormSideStyles';
import { LinkStyleButton } from 'ReusableComponents';

const Container = ({ children }) => {
  const { classes, theme } = useStyles();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const linkContainer = (
    <div className={classes.loginLinkContainer}>
      <Typography>Already a user?</Typography>
      <LinkStyleButton component={Link} to="/login">
        Log in
      </LinkStyleButton>
    </div>
  );

  return isSm ? (
    <FullScreenLayout Bottom={linkContainer}>{children}</FullScreenLayout>
  ) : (
    <div
      className={classes.formSide}
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(13, 46, 76, 0.6), rgba(13, 46, 76, 0.6)), url(${process.env.PUBLIC_URL}/images/error/spacebg.jpg)`,
      }}
    >
      <Paper className={classes.paperContainer}>{children}</Paper>
      {linkContainer}
    </div>
  );
};

export default Container;
