import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { DateCalendar, DateField, PickersDay } from '@mui/x-date-pickers';

import { Button, Popover, Typography } from '@mui/material';
import { Event } from '@mui/icons-material';

const useButtonStyles = makeStyles({ name: 'Date-Button' })((theme, _params, classes) => ({
  date: {},
}));

const useStyles = makeStyles({ name: 'Date-Popover' })((theme, _params, classes) => ({
  input: { '& .MuiInputBase-input': { padding: 9, paddingRight: 0 } },
  popoverHeader: { padding: theme.spacing(2, 2, 0, 3) },
  popoverSubheader: { padding: theme.spacing(0, 2, 0, 3) },
  day: {
    margin: 0,
    borderRadius: theme.spacing(1),
    transition: 'unset',
    [`&.${classes.between}`]: { backgroundColor: theme.palette.primary.lightOpaque },
    [`&.${classes.hover}`]: { backgroundColor: theme.palette.primary.light },
  },
  between: { borderRadius: 0 },
  active: {
    [`&.${classes.after}`]: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
    [`&.${classes.before}`]: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
  },
  hover: {
    borderRadius: theme.spacing(1),
    [`&.${classes.after}`]: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
    [`&.${classes.before}`]: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
  },
  before: {},
  after: {},
}));

const DateRangePicker = memo(({ type = 'button', value, setValue, slotProps = {} }) => {
  const { classes: styles } = useButtonStyles();
  const { from, to } = { ...value };

  const { button: buttonProps = {} } = slotProps;

  const [open, setOpen] = useState(0);

  const dateRef = useRef();

  const handleTextChange = useCallback(
    (type, value) => {
      setOpen(0);
      setValue((old) => ({ ...old, [type]: value }));
    },
    [setValue]
  );

  return (
    <>
      {type === 'textfield' ? (
        <>
          <DateField
            label="From"
            format="L"
            margin="dense"
            size="small"
            value={from}
            onClick={() => setOpen(1)}
            onChange={(value) => handleTextChange(from, value)}
          />
          <DateField
            label="To"
            format="L"
            size="small"
            value={to}
            onClick={() => setOpen(2)}
            ref={dateRef}
            onChange={(value) => handleTextChange(to, value)}
          />
        </>
      ) : (
        <Button
          variant="outlined"
          onClick={() => setOpen(1)}
          ref={dateRef}
          startIcon={<Event />}
          fullWidth
          {...buttonProps}
        >
          <span>
            <span className={styles.date}>{from?.format('L') || 'Date from'}</span>
            {' - '}
            <span className={styles.date}>{to?.format('L') || 'Date To'}</span>
          </span>
        </Button>
      )}
      <DatePopover
        open={open}
        setOpen={setOpen}
        dateRef={dateRef}
        value={value}
        setValue={setValue}
        slotProps={slotProps}
        type={type}
      />
    </>
  );
});
DateRangePicker.displayName = 'DateRangePicker';

export default DateRangePicker;

const DatePopover = memo(({ open, setOpen, value, setValue, dateRef, slotProps, disableFuture, type }) => {
  const { classes: styles } = useStyles();

  const { dateRangePicker: dateRangePickerProps = {} } = slotProps;

  const { from, to } = { ...value };

  const [hover, setHover] = useState();

  const handleClick = useCallback(
    (date) => {
      if (open === 1) {
        setValue((old) => {
          setOpen(type === 'textfield' && date?.isBefore(old?.to) ? 0 : 2);

          return { ...old, from: date };
        });
      } else {
        setValue((old) => {
          setOpen(0);

          return date?.isBefore(old?.from) ? { from: date, to: old?.from } : { ...old, to: date };
        });
      }
    },
    [open, setOpen, setValue, type]
  );

  return (
    <Popover
      open={!!open}
      anchorEl={dateRef.current}
      onClose={() => {
        (type === 'textfield' || open !== 2) && setOpen(0);
      }}
      anchorOrigin={
        type === 'textfield'
          ? { vertical: 'bottom', horizontal: 'right' }
          : { vertical: 'center', horizontal: 'center' }
      }
      transformOrigin={
        type === 'textfield' ? { vertical: 'center', horizontal: 'left' } : { vertical: 'center', horizontal: 'center' }
      }
      disableAutoFocus={type === 'textfield'}
      disableEnforceFocus={type === 'textfield'}
      slotProps={{ paper: { elevation: 2 } }}
    >
      <Typography className={styles.popoverHeader} variant="subtitle1" component="h1">
        Select Date Range
      </Typography>
      <Typography variant="body2" className={styles.popoverSubheader}>
        {`Set ${!from ? `'date from'` : from?.format('L')} - ${!to ? `'date to'` : to?.format('L')}`}
      </Typography>
      <DateCalendar
        value={open === 1 ? to ?? from : from}
        onChange={() => {}}
        slots={{ day: CustomPickersDay }}
        slotProps={{
          day: {
            custom: {
              hover,
              active: open === 2 ? from : open === 1 ? to : undefined,
              handleClick,
              setHover,
            },
          },
        }}
        disableFuture={disableFuture}
        {...dateRangePickerProps}
      />
    </Popover>
  );
});

DatePopover.displayName = 'DatePopover';

const CustomPickersDay = memo((props) => {
  const { classes: styles, cx } = useStyles();

  const { day: date, custom = {}, ...pickersDayProps } = { ...props };

  const { hover, active, handleClick, setHover } = custom;

  const isBetween = useMemo(
    () =>
      !!active &&
      !!hover &&
      date.isBetween(
        hover?.isBefore(active, 'day') ? hover : active,
        hover?.isBefore(active, 'day') ? active : hover,
        'day'
      ),
    [date, active, hover]
  );

  const [isBeforeActive, isSameActive, isAfterActive] = useMemo(
    () => [date?.isBefore(active, 'day'), date?.isSame(active, 'day'), date?.isAfter(active, 'day')],
    [date, active]
  );

  const [isBeforeHover, isSameHover, isAfterHover] = useMemo(
    () => [date?.isBefore(hover, 'day'), date?.isSame(hover, 'day'), date?.isAfter(hover, 'day')],
    [date, hover]
  );

  return (
    <PickersDay
      {...pickersDayProps}
      className={cx(
        styles.day,
        isBetween && styles.between,
        isSameActive && styles.active,
        isSameHover && styles.hover,
        !!active && isSameHover && isBeforeActive && styles.before,
        !!active && isSameHover && isAfterActive && styles.after,
        !!active && isSameActive && isBeforeHover && styles.before,
        !!active && isSameActive && isAfterHover && styles.after
      )}
      day={date}
      selected={!!active && isSameActive}
      onClick={() => handleClick(date)}
      onMouseEnter={() => setHover(date)}
      onMouseLeave={() => setHover(undefined)}
    />
  );
});

CustomPickersDay.displayName = 'CustomPickersDay';
