import { createTheme } from '@mui/material/styles';
import ApiManager from 'ApiManager';
import moment from 'moment';

const localeData = moment.localeData();

const PRIMARY = {
  surface: '#f2f7fc',
  light: '#5B9AD9',
  lightOpaque: '#EBF2FA',
  main: '#1976D2',
  mainOpaque: '#E3EEF9',
  dark: '#0A2E52',
  darkOpaque: '#E1E6EA',
  contrastText: '#fff',
};

const SECONDARY = {
  light: '#F7A04A',
  lightOpaque: '#FEF3E9',
  main: '#F57C00',
  mainOpaque: '#FDEFE0',
  dark: '#753B00',
  darkOpaque: '#EEE7E0',
  contrastText: '#fff',
};

const FONTS = [
  '"Inter"',
  '"Roboto"',
  '"-apple-system"',
  '"BlinkMacSystemFont"',
  '"Segoe UI"',
  '"Helvetica Neue"',
  '"Arial"',
  '"sans-serif"',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(', ');

const withPoppins = '"Poppins", ' + FONTS;

var getTheme = async (env) => {
  let dynamicStyle;
  if (env !== 'ellipsis') {
    //do an api call
    try {
      dynamicStyle = await ApiManager.get(
        '/v3/backoffice/theme/color',
        undefined,
        undefined,
        undefined,
        AbortSignal.timeout(5000)
      );
    } catch {
      dynamicStyle = {};
    }
  } else {
    dynamicStyle = {};
  }

  let theme = createTheme({
    palette: {
      navBar: '#ffffff',
      common: {
        black: '#000',
        gray1: '#333333',
        gray2: '#4F4F4F',
        gray3: '#828282',
        gray4: '#BDBDBD',
        gray5: '#E0E0E0',
        gray6: '#F2F2F2',
        gray7: '#323232',
        white: '#fff',
      },
      primary: PRIMARY,
      secondary: SECONDARY,
      error: {
        light: '#E57373',
        main: '#F44336',
        dark: '#D32F2F',
      },
      warning: {
        light: '#FFB74D',
        main: '#F2994A',
        dark: '#F57C00',
        contrastText: '#fff',
      },
      info: {
        light: '#4FC3F7',
        main: '#03A9F4',
        dark: '#0288D1',
        contrastText: '#fff',
      },
      success: {
        light: '#81C784',
        main: '#4CAF50',
        dark: '#388E3C',
        contrastText: '#fff',
      },
      shape: { main: '#303F9F', contrastText: '#fff' },
      pointCloud: { main: '#C2185B', contrastText: '#fff' },
      mesh: { main: '#AB47BC', contrastText: '#fff' },
      map: { main: '#00796B', contrastText: '#fff' },
      process: { main: '#FFA000', contrastText: '#fff' },
      folder: { main: '#8BC34A', contrastText: '#fff' },
      bookmark: { main: '#F57C00', contrastText: '#fff' },
      file: { main: '#03A9F4', contrastText: '#fff' },
      elevation: { main: '#1B5E20', contrastText: '#fff' },
      message: { main: '#d6a718', contrastText: '#fff' },
      ...dynamicStyle,
    },
    typography: {
      fontFamily: FONTS,
      fontSize: 14,
      h1: {
        fontFamily: withPoppins,
        fontSize: '2.875rem',
        fontWeight: 800,
        lineHeight: 1.5,
        letterSpacing: '0.03125rem',
      },
      h2: {
        fontFamily: withPoppins,
        fontSize: '2.25rem',
        fontWeight: 700,
        lineHeight: 1.5,
        letterSpacing: '0.03125rem',
      },
      h3: {
        fontFamily: withPoppins,
        fontSize: '1.5rem',
        fontWeight: 500,
        lineHeight: 1.5,
        letterSpacing: '0.03125rem',
      },
      subtitle1: {
        fontSize: '1.125rem',
        fontWeight: 700,
        lineHeight: 1.5,
        letterSpacing: '0.009375rem',
      },
      subtitle2: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: '0.00625rem',
      },
      bodyEmphasis: {
        fontSize: '1.125rem',
        lineHeight: 1.5,
      },
      body1: {},
      body2: {
        lineHeight: 1.5,
      },
      button: {
        lineHeight: 1.5,
      },
      caption: {
        lineHeight: 1.5,
        letterSpacing: '0.04rem',
      },
      overline: {
        fontSize: '0.625rem',
        lineHeight: 1.5,
        letterSpacing: '0.09375rem',
        fontWeight: 500,
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            bodyEmphasis: 'p',
          },
        },
      },
      MuiDatePickerToolbar: {
        defaultProps: {
          toolbarFormat: 'L',
        },
      },
      MuiDateTimePickerToolbar: {
        defaultProps: {
          toolbarFormat: localeData?.longDateFormat('L')?.replace(/\W*[Yy]+\W*/g, ''),
        },
      },
    },
  });

  return createTheme(theme, {
    palette: Object.entries(theme.palette).reduce(
      (pv, [key, value]) => ({
        ...pv,
        ...(typeof value === 'object' && !value?.light && !!value?.main
          ? { [key]: theme.palette.augmentColor({ color: value, name: key }) }
          : {}),
      }),
      {}
    ),
  });
};

export default getTheme;
