import React from 'react';

export const removeDuplicates = (arr) => {
  return arr.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      arr.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
};

export const arrayToObject = (arr, key, value) => {
  var rv = {};
  for (var i = 0; i < arr.length; ++i) if (arr[i] !== undefined) rv[arr[i][key]] = arr[i][value];
  return rv;
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// const RETRY_IMPORT_TIMES = 10;
const RETRY_IMPORT_TIMES = 2;
const RETRY_IMPORT_DELAY = 1000 * 3;
// retry `RETRY_IMPORT_TIMES` while waiting `RETRY_IMPORT_DELAY`ms between each try to import the component
export const retryableLazy = (lazyImport, name) => {
  return React.lazy(async () => {
    // it's important that this is implemented as a loop rather than a function
    // otherwise webpack throws a hissy fit
    let component = null;
    let counter = 0;
    let error = new Set();

    while (component === null && counter < RETRY_IMPORT_TIMES) {
      counter += 1;
      try {
        component = await lazyImport();
      } catch (err) {
        error.add(err);
        await sleep(RETRY_IMPORT_DELAY);
      }
    }

    if (component == null) {
      // TODO: add offline splash screen
      console.warn('Import failed, app possibly offline', lazyImport);
      /* error.forEach((err) => {
        console.error(err);
        console.log(err, err?.status);
      }); */
    }

    return name ? { default: component[name] } : component;
  });
};
