import React, { createContext, useContext, useRef, useState, useCallback, useMemo, useEffect } from 'react';

import copy from 'copy-to-clipboard';
import { compress } from 'compress-json';

import { useSelectedElement } from './SelectedElementContext';
import { useMainViewer, viewPosition } from './MainViewerContext';
import { useLayers } from './ViewerLayersContext';
import { useElevationModels } from './ElevationModelsContext';
import { usePaneContent } from 'Viewer/DetailsPane/PaneContentContext';
import { useMinimalAuth } from 'hooks';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useMainContext } from 'ReusableComponents';
import ApiManager from 'ApiManager';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const TARGET_THUMBNAIL_ASPECT_RATIO = 16 / 9;
const MAX_THUMBNAIL_WIDTH = 640;
const drawImageProp = function (ctx, img, x, y, w, h, offsetX, offsetY) {
  if (arguments.length === 2) {
    x = y = 0;
    w = ctx.canvas.width;
    h = ctx.canvas.height;
  }

  // default offset is center
  offsetX = typeof offsetX === 'number' ? offsetX : 0.5;
  offsetY = typeof offsetY === 'number' ? offsetY : 0.5;

  // keep bounds [0.0, 1.0]
  if (offsetX < 0) offsetX = 0;
  if (offsetY < 0) offsetY = 0;
  if (offsetX > 1) offsetX = 1;
  if (offsetY > 1) offsetY = 1;

  var iw = img.width,
    ih = img.height,
    r = Math.min(w / iw, h / ih),
    nw = iw * r, // new prop. width
    nh = ih * r, // new prop. height
    cx,
    cy,
    cw,
    ch,
    ar = 1;

  // decide which gap to fill
  if (Math.round(nw) < w) ar = w / nw;
  if (Math.round(nh) < h) ar = h / nh;
  nw *= ar;
  nh *= ar;

  // calc source rectangle
  cw = iw / (nw / w);
  ch = ih / (nh / h);

  cx = (iw - cw) * offsetX;
  cy = (ih - ch) * offsetY;

  // make sure source rectangle is valid
  if (cx < 0) cx = 0;
  if (cy < 0) cy = 0;
  if (cw > iw) cw = iw;
  if (ch > ih) ch = ih;

  // fill image in dest. rectangle
  ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
};

const viewerFuncsContext = createContext();

export const ViewerFuncsProvider = ({ children }) => {
  const history = useHistory();
  const [clickRestrictionLayer, setClickRestrictionLayer] = useState();
  const { selectedElement, compareWith } = useSelectedElement();
  const { viewerConfig, viewMode, view3dConfig } = useMainViewer();
  const { layers, layersInfo } = useLayers();
  const { models, modelsInfo } = useElevationModels();
  const user = useMinimalAuth();
  const { currentFolderInfo, getPathInfo } = useCurrentFolder();
  const paneContent = usePaneContent();
  const { onOpenSnackbar } = useMainContext();

  const twoDDRef = useRef(null);
  const threeDRef = useRef(null);

  const handleThumbnail = useCallback(
    (promise) =>
      new Promise((resolve) => {
        promise.then(({ canvas, cb = () => {} }) => {
          const size = { height: canvas?.height || 0, width: canvas?.height || 0 };
          const { height, width } = { ...size };

          const inputAspectRatio = width / height;

          const newCanvasSize = { ...size };
          if (inputAspectRatio > TARGET_THUMBNAIL_ASPECT_RATIO) {
            newCanvasSize.width = height * TARGET_THUMBNAIL_ASPECT_RATIO;
          } else if (inputAspectRatio < TARGET_THUMBNAIL_ASPECT_RATIO) {
            newCanvasSize.height = width / TARGET_THUMBNAIL_ASPECT_RATIO;
          }

          const aspectCanvas = new OffscreenCanvas(newCanvasSize?.width, newCanvasSize?.height);
          const aspectCanvasContext = aspectCanvas.getContext('2d');

          aspectCanvasContext.fillStyle = 'skyblue';
          aspectCanvasContext.fillRect(0, 0, newCanvasSize?.width, newCanvasSize?.height);

          drawImageProp(aspectCanvasContext, canvas);

          const scalingFactor = MAX_THUMBNAIL_WIDTH / newCanvasSize?.width;

          const resizeCanvas = new OffscreenCanvas(MAX_THUMBNAIL_WIDTH, (MAX_THUMBNAIL_WIDTH / 16) * 9);
          const resizeCanvasContext = resizeCanvas.getContext('2d');
          resizeCanvasContext.drawImage(
            aspectCanvas,
            0,
            0,
            newCanvasSize?.width * scalingFactor,
            newCanvasSize?.height * scalingFactor
          );

          const resizeReader = new FileReader();
          resizeReader.onload = (e) => {
            resolve(e?.target?.result);
            cb();
          };

          resizeCanvas.convertToBlob({ type: 'image/webp', quality: 0.75 }).then((blob) => {
            resizeReader.readAsDataURL(blob);
          });
        });
      }),
    []
  );

  const handleCreateShareLink = useCallback(
    async (props = {}) => {
      const { persistent = false, name, description, viewerConfig: viewerConfigN, isUpdate, parentId } = { ...props };

      const parsedRef = threeDRef?.current || twoDDRef?.current;

      const typeToType = (l) => {
        if ([1, 2, 3, 4, 5].includes(l.id)) {
          if (l.id === 1) {
            return 'base';
          } else if (l.id === 2) {
            return 'satellite';
          } else if (l.id === 3) {
            return 'hybrid';
          } else if (l.id === 4) {
            return 'topographic';
          } else if (l.id === 5) {
            return 'buildings';
          }
        } else if (['map', 'shape', 'pointCloud'].includes(l.type)) {
          return 'ellipsis';
        } else {
          return 'external';
        }
      };

      let finalElement = { ...selectedElement };
      if (finalElement.type === 'layer' || finalElement.type === 'deleted') {
        finalElement.id = finalElement.feature.properties.id;
        finalElement.feature = null;
      }

      const viewerConfigAlter = viewerConfigN ? viewerConfigN : viewerConfig ? viewerConfig : {};
      console.log('viewerConfigAlter ', viewerConfigAlter);
      // stripe pane content
      const viewerState = {
        layersInfo: layersInfo,
        demsInfo: modelsInfo,
        selectedElement: finalElement,
        compareWith: compareWith,
        viewPosistion: viewPosition,
        view3dConfig: view3dConfig,
        viewMode: viewMode,
        paneContent: {
          ...paneContent.pane,
          showPane: paneContent.showPane,
        },
        ...{ viewerConfig: viewerConfigAlter },
      };

      const viewerStateString = JSON.parse(JSON.stringify(viewerState));
      const compressed = JSON.stringify(compress(viewerStateString));

      const thumbnail = persistent ? await handleThumbnail(parsedRef?.handleThumbnailN()) : undefined;

      const layersN = layers.map((l) => ({
        type: typeToType(l),
        id: l.id,
        selected: l.isSelected,
      }));
      const dems = models.map((m) => ({ id: m.id, selected: !!m.isSelected }));
      const json = { compressed };
      console.log('layersN', layersN);
      const body = {
        layers: layersN,
        dems,
        bookmark: { dems, layers: layersN, json },
        json,
        pathId: currentFolderInfo?.id,
        name,
        parentId,
        metadata: { description },
        thumbnail,
      };
      let url;
      const apiCall = !persistent
        ? ApiManager.post(`/v3/view`, body, user)
        : isUpdate
        ? ApiManager.patch(`/v3/path/${currentFolderInfo?.id}/bookmark`, body, user)
        : ApiManager.post(`/v3/path/bookmark`, body, user);

      if (isUpdate && persistent) {
        if (name !== currentFolderInfo?.name) {
          try {
            ApiManager.put(`/v3/path/${currentFolderInfo?.id}/name`, { name }, user).then((r) => {
              getPathInfo();
            });
          } catch (e) {
            onOpenSnackbar({
              level: 'error',
              content: e.message,
            });
            console.error(e);
          }
        }
        if (description !== currentFolderInfo?.description) {
          try {
            ApiManager.patch(`/v3/path/${currentFolderInfo?.id}/metadata`, { description }, user).then((r) => {
              getPathInfo();
            });
          } catch (e) {
            onOpenSnackbar({
              level: 'error',
              content: e.message,
            });
            console.error(e);
          }
        }
      }

      return await apiCall
        .then((res) => {
          if (persistent) {
            if (isUpdate) {
              url = `/view?pathId=${currentFolderInfo?.id}`;
            } else {
              url = `/view?pathId=${res.id}`;
            }
          } else {
            url = `/view?pathId=${currentFolderInfo?.id}&state=${res.id}`;
          }

          if (viewerConfig?.hideNavbar) {
            url = url + '&hideNavbar=true';
          }
          onOpenSnackbar({
            level: 'success',
            content: isUpdate
              ? 'Updated bookmark'
              : persistent
              ? 'Bookmark saved, redirecting to bookmark...'
              : 'Copied link to your clip board',
          });

          if (!persistent) {
            copy(`${window.location.origin}${url}`);
          } else if (isUpdate) {
            history.push(url);
          } else {
            history.push(url);
          }

          return res.id;
        })
        .catch((e) => {
          onOpenSnackbar({
            level: 'error',
            content: e.message,
          });
          console.error(e);
        });
    },
    [
      selectedElement,
      viewerConfig,
      layersInfo,
      modelsInfo,
      compareWith,
      view3dConfig,
      viewMode,
      paneContent.pane,
      paneContent.showPane,
      handleThumbnail,
      layers,
      models,
      currentFolderInfo?.id,
      currentFolderInfo?.name,
      currentFolderInfo?.description,
      user,
      getPathInfo,
      onOpenSnackbar,
      history,
    ]
  );

  const value = useMemo(
    () => ({
      handleThumbnail,
      clickRestrictionLayer,
      setClickRestrictionLayer,
      handleCreateShareLink,
      twoDDRef,
      threeDRef,
    }),
    [handleThumbnail, clickRestrictionLayer, setClickRestrictionLayer, handleCreateShareLink]
  );

  return <viewerFuncsContext.Provider value={value}>{children}</viewerFuncsContext.Provider>;
};

export function useViewerFuncs() {
  const context = useContext(viewerFuncsContext);
  if (context === undefined) {
    throw new Error('useExtraLayers must be used within an extraLayersProvider');
  }
  return context;
}

export function withViewerFuncs(Component) {
  return function WrapperComponent(props) {
    const context = useViewerFuncs();
    return <Component {...props} {...context} />;
  };
}

export function withViewerFuncsContext(Component) {
  return function WrapperComponent(props) {
    return (
      <ViewerFuncsProvider>
        <Component {...props} />
      </ViewerFuncsProvider>
    );
  };
}
