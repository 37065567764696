import React from 'react';

import { Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CheckCircleOutline } from '@mui/icons-material';

import Astronaut from './astronaut.png';
import EllipsisLogo from './Ellipsis_Drive_Full_Blue_Logo.png';

const useStyles = makeStyles()((theme) => ({
  displaySide: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  displayOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    borderBottomRightRadius: '80px',
    borderTopRightRadius: '80px',
  },
  astronautImage: {
    position: 'absolute',
    top: '150px',
    right: '8%',
    height: '450px',
    [theme.breakpoints.down('lg')]: {
      height: '350px',
      top: '250px',
      right: '5%',
    },
  },
  content: {
    marginTop: '5vh',
    height: 'calc(100% - 80px)',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {},
  detailsTitle: {},
  listItem: {
    paddingLeft: 0,
  },
  listItemIcon: {
    minWidth: theme.spacing(4),
  },
  listItemText: {},
  ellipsisLogo: {
    width: '300px',
    alignSelf: 'center',
    marginBottom: '55px',
  },
}));

const RegisterPage = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.displaySide}>
      <div className={classes.displayOverlay}>
        <img src={Astronaut} alt="Astronaut" className={classes.astronautImage} />
        <div className={classes.content}>
          <div>
            <Typography className={classes.title} variant="h1">
              Get started for{' '}
              <Typography variant="inherit" color="primary" component="span">
                free
              </Typography>
            </Typography>

            <List className={classes.root}>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <CheckCircleOutline color="primary" fontSize="inherit" />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemText }} primary="Host up to 5GB on your account" />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <CheckCircleOutline color="primary" fontSize="inherit" />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemText }} primary="Join existing drive content" />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <CheckCircleOutline color="primary" fontSize="inherit" />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemText }} primary="View and search community content" />
              </ListItem>
            </List>
          </div>

          <img src={EllipsisLogo} alt="Ellipsis Drive" className={classes.ellipsisLogo} />
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
