import ApiManager from 'ApiManager';
import VersionUtility from 'VersionUtility';

import { currentName } from '../Drive';

const toggleFavorite = ({
  path,
  user,
  onOpenSnackbar,
  handleOptimisticUpdate,
  mainMode,
  breadcrumbPath,
  resetSelectedItems,
}) => {
  const currentFavorite = path.favorite;

  const updateFunc =
    mainMode === '/drive/favorites' && breadcrumbPath?.length === 0
      ? (items) => items.filter((item) => item.id !== path.id)
      : (items) => items?.map((item) => (item.id === path.id ? { ...item, favorite: !currentFavorite } : item));
  const revertFunc =
    mainMode === '/drive/favorites' && breadcrumbPath?.length === 0
      ? (items) => [...items, path]
      : (items) => items?.map((item) => (item.id === path.id ? { ...item, favorite: currentFavorite } : item));

  const apiCall = ApiManager.put(`/v3/path/${path.id}/favorite`, { favorite: !currentFavorite }, user);

  const onSuccess = () => {
    if (mainMode === '/drive/favorites') {
      resetSelectedItems();
    }
    onOpenSnackbar({
      level: 'success',
      content: currentFavorite
        ? `Path "${path.name}" successfully removed from favorites`
        : `Path "${path.name}" successfully added to favorites`,
    });
  };
  const onError = (e) => {
    const message = currentFavorite
      ? `There was an error while removing ${
          path.type === 'map' ? 'raster' : path.type === 'shape' ? 'vector' : path.type
        } "${path.name}" from favorites`
      : `There was an error while adding ${
          path.type === 'map' ? 'raster' : path.type === 'shape' ? 'vector' : path.type
        } "${path.name}" to favorites`;
    onOpenSnackbar({
      level: 'error',
      content: e?.message ? message + `: ${e.message}` : message,
    });
  };

  let updateBody = { apiCall, onSuccess, onError };
  if (path.type === 'folder') {
    updateBody = { updateFolders: updateFunc, revertFoldersUpdate: revertFunc, ...updateBody };
  } else {
    updateBody = { updateMaps: updateFunc, revertMapsUpdate: revertFunc, ...updateBody };
  }

  handleOptimisticUpdate(updateBody);
};

const downloadFile = (fileId, user) => {
  let url = `${process.env.REACT_APP_API_URL}/v3/path/${fileId}/file/data`;
  if (user) {
    url = url + '?token=' + user.token;
  }
  window.open(url);
};

const handleRemoveAccess = ({ path, user, onOpenSnackbar, handleOptimisticUpdate }) => {
  const updateFunc = (items) => items.filter((item) => item.id !== path.id);
  const revertFunc = (items) => [...items, path];
  const apiCall = ApiManager.delete(`/v3/path/${path.id}/member/${user.id}`, null, user);

  const onSuccess = () => {
    onOpenSnackbar({
      level: 'success',
      content: `Access to "${path.name}" has successfully been removed`,
    });
  };

  const onError = (e) => {
    const message = `There was an error while removing access from "${path.name}"`;
    onOpenSnackbar({
      level: 'error',
      content: e?.message ? message + `: ${e.message}` : message,
    });
  };

  let updateBody = { apiCall, onSuccess, onError };
  if (path.type === 'folder') {
    updateBody = { updateFolders: updateFunc, revertFoldersUpdate: revertFunc, ...updateBody };
  } else {
    updateBody = { updateMaps: updateFunc, revertMapsUpdate: revertFunc, ...updateBody };
  }

  handleOptimisticUpdate(updateBody);
};

const handleRename = ({ trimmedName, path, onOpenSnackbar, handleOptimisticUpdate, user }) => {
  const updateFunc = (maps) => maps.map((item) => (item.id === path.id ? { ...item, name: trimmedName } : item));
  const revertFunc = (maps) => maps.map((item) => (item.id === path.id ? { ...item, name: path.name } : item));

  const apiCall = ApiManager.put(`/v3/path/${path.id}/name`, { name: trimmedName }, user);
  const onSuccess = () => {
    onOpenSnackbar({
      level: 'success',
      content: `Path "${path.name}" successfully renamed to "${trimmedName}"`,
    });
  };

  const onError = () =>
    onOpenSnackbar({
      level: 'error',
      content: `There was an error while renaming path "${path.name}"`,
    });

  let updateBody = { apiCall, onSuccess, onError };
  if (path.type === 'folder') {
    updateBody = { updateFolders: updateFunc, revertFoldersUpdate: revertFunc, ...updateBody };
  } else {
    updateBody = { updateMaps: updateFunc, revertMapsUpdate: revertFunc, ...updateBody };
  }

  handleOptimisticUpdate(updateBody);
};

const handleDescription = ({ description, path, onOpenSnackbar, handleOptimisticUpdate, user }) => {
  const updateFunc = (maps) => maps.map((item) => (item.id === path.id ? { ...item, description: description } : item));
  const revertFunc = (maps) => maps.map((item) => (item.id === path.id ? { ...item, name: path.description } : item));

  const apiCall = ApiManager.patch(`/v3/path/${path.id}/metadata`, { description: description }, user);
  const onSuccess = () => {
    onOpenSnackbar({
      level: 'success',
      content: `Description successfully updated`,
    });
  };

  const onError = () =>
    onOpenSnackbar({
      level: 'error',
      content: `There was an error while updating the description`,
    });

  let updateBody = { apiCall, onSuccess, onError };
  if (path.type === 'folder') {
    updateBody = { updateFolders: updateFunc, revertFoldersUpdate: revertFunc, ...updateBody };
  } else {
    updateBody = { updateMaps: updateFunc, revertMapsUpdate: revertFunc, ...updateBody };
  }

  handleOptimisticUpdate(updateBody);
};

const handleProcessEdit = ({ process, path, onOpenSnackbar, handleOptimisticUpdate, user, onSuccessCb, onErrorCb }) => {
  const updateFunc = (maps) => maps.map((item) => (item.id === path.id ? { ...item, process } : item));
  const revertFunc = (maps) => maps.map((item) => (item.id === path.id ? { ...item, name: path.process } : item));

  const apiCall = ApiManager.patch(`/v3/path/${path?.id}/process`, process, user);
  const onSuccess = () => {
    typeof onSuccessCb === 'function'
      ? onSuccessCb()
      : onOpenSnackbar({
          level: 'success',
          content: `${path?.name} successfully updated`,
        });
  };

  const onError = (e) =>
    typeof onErrorCb === 'function'
      ? onErrorCb(e)
      : onOpenSnackbar({
          level: 'error',
          content: `There was an error while updating ${path?.name}`,
        });

  let updateBody = { apiCall, onSuccess, onError };
  if (path.type === 'folder') {
    updateBody = { updateFolders: updateFunc, revertFoldersUpdate: revertFunc, ...updateBody };
  } else {
    updateBody = { updateMaps: updateFunc, revertMapsUpdate: revertFunc, ...updateBody };
  }

  handleOptimisticUpdate(updateBody);
};

// getStorage here is used to fetch map/folder size
const getStorage = async (storage, path, user, isMap, setTotalStorage) => {
  if (isMap) {
    try {
      storage = VersionUtility.convertPathInfo(await ApiManager.get(`/v3/path/${path.id}`, null, user));
      storage = storage.storageSize;
    } catch {
      console.log('error occured');
    }
  } else {
    let cont;
    let pageStart;
    let allFolders = [];
    cont = true;
    while (cont) {
      let maps = [];
      try {
        maps = await ApiManager.get(
          `/v3/path/${path.id}/list`,
          {
            type:
              ApiManager?.cloud === 'esa'
                ? ['raster', 'vector', 'pointCloud', 'bookmark', 'process', 'file']
                : ['raster', 'vector', 'pointCloud', 'bookmark', 'file'],
            includeTrashed: false,
            pageStart: pageStart,
          },
          user
        );
        maps = VersionUtility.convertListRoot(maps);
        maps.result = maps.result.filter((m) => !m.deleted);
      } catch {
        console.log('error occured');
      }
      pageStart = maps.nextPageStart;

      for (let i = 0; i < maps.result.length; i++) {
        storage = storage + maps.result[i].storageSize;
      }
      setTotalStorage(storage);

      if (!pageStart) {
        cont = false;
      }
    }
    cont = true;
    while (cont) {
      let folders = [];
      try {
        folders = await ApiManager.get(
          `/v3/path/${path.id}/list`,
          { type: ['folder'], includeTrashed: false, pageStart: pageStart },
          user
        );
        folders = VersionUtility.convertListRoot(folders);
        folders.result = folders.result.filter((f) => !f.deleted);
      } catch {
        console.log('error occured');
      }
      pageStart = folders.nextPageStart;
      allFolders = allFolders.concat(folders.result);
      if (!pageStart) {
        cont = false;
      }
    }

    for (let i = 0; i < allFolders.length; i++) {
      storage = await getStorage(storage, allFolders[i], user, false, setTotalStorage);
    }
  }

  return storage;
};

const listAll = async (folderId, user, isFolder) => {
  let folderMaps = null;
  let pageStartFolderMaps = null;

  while (pageStartFolderMaps !== null || folderMaps === null) {
    let res = await ApiManager.get(
      `/v3/path/${folderId}/list`,
      {
        type: isFolder
          ? ['folder']
          : ApiManager?.cloud === 'esa'
          ? ['raster', 'vector', 'pointCloud', 'bookmark', 'process', 'file']
          : ['raster', 'vector', 'pointCloud', 'bookmark', 'file'],
        includeTrashed: true,
        pageStart: pageStartFolderMaps,
      },
      user
    );
    res = VersionUtility.convertListRoot(res);
    folderMaps = folderMaps ? [...folderMaps, res.result].flat() : res.result;
    pageStartFolderMaps = res.nextPageStart;
  }
  return folderMaps;
};

const removeFolder = async (folderId, user) => {
  await ApiManager.delete(`/v3/path/${folderId}`, null, user);
  return;
  /* let folderMaps = await listAll(folderId, user, false);

  // delete all maps in folder
  for (let j = 0; j < folderMaps.length; j++) {
    await ApiManager.delete(`/v3/path/${folderMaps[j].id}`, null, user);
  }

  let subFolders = await listAll(folderId, user, true);

  for (let i = 0; i < subFolders.length; i++) {
    //recurse through subfolders
    await removeFolder(subFolders[i].id, user);
  }

  //delete folder itself
  await ApiManager.delete(`/v3/path/${folderId}`, null, user); */
};

const handleDeleteItems = ({
  deleteItems,
  onOpenSnackbar,
  handleOptimisticUpdate,
  user,
  resetSelectedItems,
  hard = false,
  cb,
}) => {
  const pathIds = deleteItems.map((item) => item.id);
  const deleteMaps = deleteItems.filter((item) => item.type !== 'folder');
  const deleteFolders = deleteItems.filter((item) => item.type === 'folder');

  const revert = deleteItems[0].deleted && !hard;

  const updateMapsFunc = (maps) => {
    return maps.filter((item) => !pathIds.includes(item.id));
  };

  const updateFoldersFunc = (folders) => {
    return folders.filter((item) => !pathIds.includes(item.id));
  };

  const revertMapsFunc = (maps) => [...maps, ...deleteMaps];

  const revertFoldersFunc = (folders) => [...folders, ...deleteFolders];

  let apiCall;

  if (hard) {
    apiCall = Promise.all(pathIds.map((pathId) => ApiManager.delete(`/v3/path/${pathId}`, null, user)));
  } else {
    apiCall = Promise.all(
      pathIds.map((pathId) => ApiManager.put(`/v3/path/${pathId}/trashed`, { trashed: !revert }, user))
    );
  }

  const onSuccess = () => {
    resetSelectedItems && resetSelectedItems();
    cb && cb();
    onOpenSnackbar({
      level: 'success',
      content: revert
        ? `${pathIds.length} item${pathIds.length > 1 ? 's' : ''} successfully restored to Drive`
        : hard
        ? `${pathIds.length} item${pathIds.length > 1 ? 's' : ''} successfully permanently deleted`
        : `${pathIds.length} item${pathIds.length > 1 ? 's' : ''} successfully moved to trash`,
    });
  };

  const onError = (e) => {
    const message = revert
      ? `There was an error while moving ${pathIds.length} item${pathIds.length > 1 ? 's' : ''} back to Drive`
      : hard
      ? `There was an error while permanently deleting ${pathIds.length} item${pathIds.length > 1 ? 's' : ''}`
      : `There was an error while moving ${pathIds.length} item${pathIds.length > 1 ? 's' : ''} to trash`;
    onOpenSnackbar({
      level: 'error',
      content: e?.message ? message + `: ${e.message}` : message,
    });
  };

  let updateBody = { apiCall, onSuccess, onError };

  updateBody = {
    updateMaps: updateMapsFunc,
    updateFolders: updateFoldersFunc,
    revertMapsUpdate: revertMapsFunc,
    revertFoldersUpdate: revertFoldersFunc,
    ...updateBody,
  };

  handleOptimisticUpdate(updateBody);
};

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

const handleFolderMove = ({
  movedItems,
  folder,
  user,
  resetSelectedItems,
  onOpenSnackbar,
  handleOptimisticUpdate,
  isBreadcrumb,
  cb,
}) => {
  let toMoveFolders = movedItems.filter((i) => i.type === 'folder');
  let toMoveLayers = movedItems.filter((i) => i.type !== 'folder');

  const pathIds = toMoveFolders.map((item) => item.id);
  const isCurrent = folder?.type === currentName;
  const layerPathIds = toMoveLayers.map((item) => item.id);

  const optimisticMoveFolders = (folders) => folders?.filter((folder) => !pathIds.includes(folder?.id)) ?? [];
  const optimisticMoveLayers = (layers) => layers?.filter((layer) => !layerPathIds.includes(layer?.id)) ?? [];

  // if it's to the current folder there's nothing to cancel

  const cancelMoveFolders = (folders) => (folders?.length > 0 ? [...toMoveFolders, ...folders] : toMoveFolders);
  const cancelMoveLayers = (layers) => (layers?.length > 0 ? [...toMoveLayers, ...layers] : toMoveLayers);

  // we can move max 10 items per API call
  const moveLayers = async (toMove, destination) => {
    if (toMove.length === 0) {
      return;
    }
    if (toMove.length <= 10) {
      await ApiManager.put('/v3/path/parentId', { pathIds: toMove.map((x) => x.id), parentId: destination }, user);
    } else {
      const res = sliceIntoChunks(toMove, 10);
      for (let k = 0; k < res.length; k++) {
        await ApiManager.put(
          '/v3/path/parentId',
          { pathIds: res[k].map((map) => map.id), parentId: destination },
          user
        );
      }
    }
  };

  const moveFolder = async (movedFolder, destinationId) => {
    //create folder
    let folderId = await ApiManager.post(
      '/v3/path',
      { parentId: destinationId, name: movedFolder.name, type: 'folder' },
      user
    );

    folderId = folderId.id;

    //get all layers
    let layers = await listAll(movedFolder.id, user, false);
    //get all folders
    let subFolders = await listAll(movedFolder.id, user, true);
    //do move on all layers
    layers.length > 0 && (await moveLayers(layers, folderId));
    //do move on all folders
    for (let n = 0; n < subFolders.length; n++) {
      await moveFolder(subFolders[n], folderId);
    }

    //remove the folder
    await ApiManager.put(`/v3/path/${movedFolder.id}/trashed`, { trashed: true }, user);
    await ApiManager.delete(`/v3/path/${movedFolder.id}`, null, user);
  };

  const fullFunction = async () => {
    await moveLayers([...toMoveLayers], folder?.id);
    for (let n = 0; n < toMoveFolders.length; n++) {
      await moveFolder(toMoveFolders[n], folder?.id);
    }
  };

  const apiCall = fullFunction();

  const onSuccess = () => {
    resetSelectedItems && resetSelectedItems();
    typeof cb === 'function' && cb();
    onOpenSnackbar({
      level: 'success',
      content: `Successfully moved ${movedItems.length} item${movedItems.length > 1 ? 's' : ''} into ${
        isCurrent ? 'the current folder' : folder?.name ? `folder "${folder.name}"` : '"My Drive"'
      }.`,
    });
  };

  const onError = (e) => {
    typeof cb === 'function' && cb();

    const message = 'Name already exists in the destination folder';
    onOpenSnackbar({
      level: 'error',
      content: e?.message ? e.message : message,
    });
  };

  let params = {
    apiCall,
    onSuccess,
    onError,
    updateFolders: optimisticMoveFolders,
    revertFoldersUpdate: cancelMoveFolders,
    updateMaps: optimisticMoveLayers,
    revertMapsUpdate: cancelMoveLayers,
  };
  handleOptimisticUpdate(params);
};

const handleItemMove = ({
  movedItems,
  folder,
  user,
  resetSelectedItems,
  onOpenSnackbar,
  handleOptimisticUpdate,
  isBreadcrumb,
  cb,
}) => {
  if (movedItems.length === 0) {
    return;
  }

  const pathIds = movedItems.map((item) => item.id);
  const newParentId = folder?.id;
  const isCurrent = folder?.type === currentName;
  const movedMaps = movedItems.filter((item) => item.type === 'map' || item.type === 'shape');
  const movedFolders = movedItems.filter((item) => item.type === 'folder');

  const moveMaps = (maps) =>
    isCurrent
      ? maps?.length > 0
        ? [...maps, ...movedMaps]
        : movedMaps
      : // remove maps that are being moved
        maps?.filter((map) => !pathIds.includes(map.id)) ?? [];
  const moveFolders = (folders) =>
    isCurrent
      ? folders?.length > 0
        ? [...folders, ...movedFolders]
        : movedFolders
      : // remove folders that are being moved
        folders?.filter((folder) => !pathIds.includes(folder?.id)) ?? [];

  // if it's to the current folder there's nothing to cancel
  const cancelMoveMaps = (maps) =>
    !isCurrent
      ? maps?.length > 0
        ? [...movedMaps, ...maps]
        : movedMaps
      : // remove maps that are being moved
        maps?.filter((map) => !pathIds.includes(map.id)) ?? [];
  const cancelMoveFolders = (folders) =>
    !isCurrent
      ? folders?.length > 0
        ? [...movedFolders, ...folders]
        : movedFolders
      : // remove folders that are being moved
        folders?.filter((folder) => !pathIds.includes(folder?.id)) ?? [];

  let apiCall;
  // we can move max 10 items per API call
  if (movedItems.length <= 10) {
    apiCall = ApiManager.put('/v3/path/parentId', { pathIds, parentId: newParentId }, user);
  } else {
    apiCall = Promise.all(
      sliceIntoChunks(movedItems, 10).map((chunk) =>
        ApiManager.put('/v3/path/parentId', { pathIds: chunk.map((map) => map.id), parentId: newParentId }, user)
      )
    );
  }
  const onSuccess = () => {
    resetSelectedItems && resetSelectedItems();
    typeof cb === 'function' && cb();
    onOpenSnackbar({
      level: 'success',
      content: `Successfully moved ${movedItems.length} item${movedItems.length > 1 ? 's' : ''} into ${
        isCurrent ? 'the current folder' : folder?.name ? `folder "${folder.name}"` : '"My Drive"'
      }.`,
    });
  };

  const onError = (e) => {
    onOpenSnackbar({
      level: 'error',
      content: e?.message,
    });
  };

  let params = {
    type: 'map',
    apiCall,
    onSuccess,
    onError,
    updateMaps: moveMaps,
    updateFolders: moveFolders,
    revertMapsUpdate: cancelMoveMaps,
    revertFoldersUpdate: cancelMoveFolders,
  };

  handleOptimisticUpdate(params);
};

const getErrorComp = (error, map, user) => {
  let errorComp = { case: error };

  switch (error) {
    case 'init':
      errorComp.disabled = true;
      errorComp.loading = true;
      break;
    case 'Layer trashed':
      errorComp.disabled = true;
      errorComp.title = 'Trashed';
      break;
    case 'Relocating layer':
      errorComp.title = 'Relocating Data Host';
      errorComp.disabled = true;
      errorComp.loading = true;
      break;
    case 'Reindexing layer':
      errorComp.title = 'Reindexing layer';
      errorComp.disabled = true;
      errorComp.loading = true;
      break;
    case 'Activating files':
      errorComp.title = 'Activating files';
      errorComp.disabled = true;
      errorComp.loading = true;
      break;
    case 'Pausing files':
      errorComp.title = 'Pausing files';
      errorComp.disabled = true;
      errorComp.loading = true;
      break;

    case 'Layer disabled':
      errorComp.title = map?.user?.id === user?.id ? 'Disabled because negative balance' : 'Disabled';
      errorComp.url = map?.user?.id === user?.id ? '/storage' : undefined;
      errorComp.actionText = map?.user?.id === user?.id ? 'Change Balance' : 'Contact owner';
      errorComp.disabled = true;
      errorComp.contact = map?.user?.id !== user?.id;
      break;

    case 'No uploads':
      errorComp.title = 'No uploads';
      errorComp.actionText =
        map?.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+'] ? 'Upload files' : 'Contact owner';
      errorComp.contact = map?.yourAccess.accessLevel < ApiManager.newAccessLevel['edit+'];
      errorComp.disabled = true;
      break;

    case 'No access':
      errorComp.title = 'No Access';
      errorComp.actionText = 'Contact owner';
      errorComp.contact = true;
      errorComp.disabled = true;
      break;
    case 'No active timestamps':
      errorComp.title = 'No Active timestamp';
      errorComp.actionText =
        map?.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+'] ? 'Activate' : 'Contact owner';
      errorComp.contact = map?.yourAccess.accessLevel < ApiManager.newAccessLevel['edit+'];
      errorComp.disabled = true;
      break;
    case 'No timestamps':
      errorComp.title = 'No Timestamps';
      errorComp.actionText =
        map?.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+'] ? 'Add timestamp' : 'Contact owner';
      errorComp.contact = map?.yourAccess.accessLevel < ApiManager.newAccessLevel['edit+'];
      errorComp.disabled = true;
      break;
    case 'No features':
      errorComp.title = 'No features';
      break;
    case 'No styles':
      errorComp.title = 'No styles';
      errorComp.url = '/settings/styles?pathId=' + map?.id;
      errorComp.actionText = 'Add style';
      errorComp.disabled = true;
      break;
    case 'Too large to Thumbnail':
      errorComp.title = 'Too large to Thumbnail';
      errorComp.disabled = false;
      break;
    default:
      errorComp = null;
  }

  return errorComp;
};

const rootNameMapping = {
  myMaps: 'My Drive',
  shared: 'Shared with me',
  favorites: 'Favorites',
  external: 'External',
};

const ICON_HEIGHT = 40;

export {
  downloadFile,
  getErrorComp,
  getStorage,
  handleDeleteItems,
  handleDescription,
  handleProcessEdit,
  handleFolderMove,
  handleItemMove,
  handleRemoveAccess,
  handleRename,
  removeFolder,
  rootNameMapping,
  toggleFavorite,
  ICON_HEIGHT,
};
