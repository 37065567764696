import React from 'react';

import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

const ViewerDialog = ({
  title,
  open,
  setOpen,
  children,
  withActions = true,
  DialogProps = {},
  DialogTitleProps = {},
  DialogContentProps = {},
  DialogActionsProps = {},
}) => {
  return (
    <Dialog open={open} fullWidth maxWidth="lg" {...DialogProps}>
      {title && <DialogTitle title={title} close={() => setOpen(false)} {...DialogTitleProps} />}
      <DialogContent {...DialogContentProps}>{children}</DialogContent>
      {withActions && (
        <DialogActions {...DialogActionsProps}>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ViewerDialog;
