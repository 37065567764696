import React from 'react';

import { DialogContent } from '@mui/material';

import Links from 'MapManagement/GeneralSettings/Links';

const Integrate = ({ path, layerInfo }) => {
  return (
    <DialogContent>
      <Links map={path} type="manage" noTitle={true} layerInfo={layerInfo} />
    </DialogContent>
  );
};

export default Integrate;
