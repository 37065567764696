import React, { useState, useEffect, useCallback } from 'react';

import ApiManager from 'ApiManager';

import { AvatarWithInfo, SectionTitle, useMainContext, UserSelect } from '../../../ReusableComponents';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemAvatar,
} from '@mui/material';
import { useMinimalAuth } from '../../AuthContext';

import { Add, Delete } from '@mui/icons-material';
import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';

const Admins = () => {
  const user = useMinimalAuth();

  const [admins, setAdmins] = useState([]);
  const { onOpenSnackbar } = useMainContext();

  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const addAdmin = useCallback(() => {
    ApiManager.post(`/v3/organization/admin`, { userId: selectedUser.id }, user)
      .then((r) => {
        onOpenSnackbar({ status: 'success', content: 'User added as organization admin' });
        setOpen(false);
        setAdmins([...admins, selectedUser]);
        setSelectedUser(null);
      })
      .catch((e) => {
        console.error(e);
        onOpenSnackbar({
          level: 'error',
          content: e.message,
        });
      });
  }, [admins, onOpenSnackbar, selectedUser, user]);

  const removeAdmin = useCallback(
    (u) => {
      ApiManager.delete(`/v3/organization/admin/${u.id}`, {}, user)
        .then((res) => {
          setAdmins(admins.filter((x) => x.id !== u.id));
          onOpenSnackbar({ status: 'success', content: 'User removed as organization admin' });
        })
        .catch((e) => {
          console.error(e);
          onOpenSnackbar({ level: 'error', content: e.message });
        });
    },
    [admins, onOpenSnackbar, user]
  );

  useEffect(() => {
    ApiManager.get(`/v3/organization/admin`, {}, user)
      .then((r) => {
        setAdmins(r);
      })
      .catch((err) => {
        console.error(err);
        onOpenSnackbar({
          level: 'error',
          content: 'Error fetching admins',
        });
      });
  }, [onOpenSnackbar, user]);

  return (
    <div>
      <SectionTitle first={true}>
        <SectionTitle.SectionTitleText>Organization admins</SectionTitle.SectionTitleText>
      </SectionTitle>
      {admins?.length > 0 && (
        <List sx={{ mb: 2 }}>
          {admins?.map((o) => (
            <ListItem key={o.id}>
              <ListItemAvatar>
                <AvatarWithInfo user={o} ButtonProps={{ sx: { m: -1 } }} />
              </ListItemAvatar>
              <ListItemText primary={o.username} />
              <Tooltip title={admins.length === 1 ? `Can't remove the last admin` : `Remove ${o.username} as admin`}>
                <span>
                  <IconButton
                    disabled={admins.length === 1}
                    onClick={() => {
                      removeAdmin(o);
                    }}
                  >
                    <Delete disabled={admins.length === 1} />
                  </IconButton>
                </span>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      )}

      <Button
        startIcon={<Add />}
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
      >
        Add Organization admins
      </Button>

      <Dialog open={!!open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle onClose={() => setOpen(false)}>{'Adding a new organization admin'}</DialogTitle>
        <DialogContent>
          <UserSelect value={selectedUser} user={user} emailSwitch={true} onChange={(u) => setSelectedUser(u)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="inherit">
            Cancel
          </Button>
          <Button disabled={!selectedUser} onClick={() => addAdmin()} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Admins;
