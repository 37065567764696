import React from 'react';

import { KeyboardArrowDown } from '@mui/icons-material';
import { Box } from '@mui/material';

const RotatableArrow = ({ in: on, start = 0, rotate = 180, icon: Icon }) => (
  <Box
    component={Icon ?? KeyboardArrowDown}
    sx={(theme) => ({
      transition: theme.transitions.create('transform'),
      transform: `rotate(${isNaN(start) ? start : `${start}deg`})`,
      ...(on ? { transform: `rotate(${isNaN(rotate) ? rotate : `${rotate}deg`})` } : {}),
    })}
  />
);

export default RotatableArrow;
