import React from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import debounce from 'lodash/debounce';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import { ThemeProvider } from '@mui/material/styles';

import ModalView from './ModalView/ModalView';
import NavigationHelper from './NavigationHelper';
import SnackbarControl from './SnackbarControl';
import Demo from './Demo/Demo';
import ReferralInvite from './ReferralInvite/ReferralInvite';

import AppUtility from './AppUtility';

import './App.css';

import { MainContext } from './ReusableComponents/MainContext';

import { UserInfoDialog } from './ReusableComponents';
import AllContexts from './AllContexts';
import GlobalErrorBoundary from './GlobalErrorBoundary';
import NewProjectDialog from './NewProjectDialog';

import Contact from 'Contact/Contact';

import UploadDialog, { UploadHeader, UploadItems, UploadContent } from './UploadDialog';
import TourDialog from 'TourDialog/TourDialog';

import getTheme from './theme';

import 'intro.js/introjs.css';
import './tourStyling.css';

import { retryableLazy } from 'globalUtils';
import ApiManager from 'ApiManager';
import CookieNotification from 'CookieNotification';

import { ConsentProvider } from 'ConsentContext';
import SentryRoute from 'ReusableComponents/SentryRoute';
import SuspenseContainer from 'ReusableComponents/SuspenseContainer';
import NavBar from 'Navbar/Navbar';
import Sidebar from 'Sidebar/Sidebar';
import { CLOUDS } from 'ApiUrls';
import { Box, CssBaseline } from '@mui/material';

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const Drive = retryableLazy(() => import('./Drive'));

const Invoice = retryableLazy(() => import('Invoice/Invoice'));

const Plugins = retryableLazy(() => import('Plugins/Plugins'));
const Services = retryableLazy(() => import('Services/Services'));
const Backoffice = retryableLazy(() => import('Backoffice/Backoffice'));

const Storage = retryableLazy(() => import('Storage/Storage'));

const Search = retryableLazy(() => import('Search/Search'));
const Page404 = retryableLazy(() => import('Page404'));

const Disclosure = retryableLazy(() => import('Disclosure'));

const LoginPage = retryableLazy(() => import('Account/LoginPage'));

const ForgotPasswordPage = retryableLazy(() => import('Account/ForgotPasswordPage'));
const RegisterPage = retryableLazy(() => import('Account/RegisterPage'));
const RegistrationPage = retryableLazy(() => import('Account/RegistrationPage'));
const VerificationNeededPage = retryableLazy(() => import('Account/VerificationNeededPage'));
const AccountManagement = retryableLazy(() => import('Account/AccountManagement'));
const OAuthConsent = retryableLazy(() => import('Account/OAuthConsent'));
const UpdateCredentials = retryableLazy(() => import('Account/UpdateCredentials'));

const ThumbnailPage = retryableLazy(() => import('ThumbnailPage'));

// const NoAccess = retryableLazy(() => import('./NoAccess'));
// const MapDisabled = retryableLazy(() => import('./MapDisabled'));

export const NAVBAR_BREAK = 'md';
let theme;
class App extends React.Component {
  overview = null;

  constructor(props) {
    super(props);

    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `/favicons/${ApiManager.cloud === CLOUDS.ellipsis ? 'favicon' : ApiManager?.cloud}.ico?v=${
      ApiManager?.cloud
    }`;

    let touch = 'ontouchstart' in window;
    //based on url
    this.leavingPage = false;
    this.state = {
      init: false,
      touch: touch,
      mainMode: null,
      [AppUtility.subModeKeys.balance]: AppUtility.balanceModes.ledger,
      windowWidth: window.innerWidth,
      selectedMap: null,
      viewerKey: 0,
      newCaptureKey: { key: 0, e: null },
      noAccess: false,
      tourOpen: false,
      NewProjectDialogOpen: false,
      tourFunctions: {},
      tutorialSteps: [],
      tutorialChangeStep: () => {},
      openSidebar: false,
      openRegister: false,
      currentPage: '',
      hubspotUrlInfo: this.getHubSpotInfo(),

      modalContent: { open: false },
      openDialog: false,

      openNewProjectDialog: false,

      hideNavbar: true,

      redirect: null,
      snackbarOpts: null,

      // used to fetch a transferred project a display it in the Drive when relevant
      addedPath: { path: null, parentId: null, mainMode: null },
    };

    this.viewerButtonRef = React.createRef();

    this.debouncedOnWindowResize = debounce(this.onWindowResize.bind(this), 150);

    this.onOpenSnackbar = (opts) => this.setState({ snackbarOpts: opts });
  }

  fetchTheme = async () => {
    theme = await getTheme(ApiManager.cloud);
    // theme = createMuiTheme(t);
    this.setState({ x: 'update it' });
  };

  //TODO all user polling and handling should be moved to AuthContext
  componentDidMount = () => {
    if (!theme) {
      this.fetchTheme();
    }

    window.addEventListener('resize', this.debouncedOnWindowResize);

    //detect if user is using iOS, and specifically safari for iOS
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    this.setState({ isiOS: iOS, isiOSSafari: iOSSafari });

    this.debouncedOnWindowResize();
    this.checkNavbar();

    this.setTourFunctions('general', {
      'Ellipsis Drive': (cb) => this.onOpenSidebar(true, cb),
    });
  };

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', () => {});

    window.removeEventListener('resize', this.debouncedOnWindowResize);
  };

  getHubSpotInfo = () => {
    return 'https://app.ellipsis-drive.com' + window.location.pathname + window.location.search;
  };

  setTourFunctions = (page, tourFunctions) => {
    this.setState((prevState) => {
      const newTourFunctions = { ...prevState.tourFunctions, [page]: tourFunctions };
      return { tourFunctions: newTourFunctions };
    });
  };

  setTourOpen = (open = true, cb) => {
    this.setState({ tourOpen: open }, typeof cb === 'function' ? cb : undefined);
  };

  setHideNavbar = (hide) => {
    this.setState({ hideNavbar: hide });
  };

  onWindowResize = () => {
    if (this.state.tourOpen) {
      return;
    }
    let isMobile = window.innerWidth < 1000;
    let stateObj = { windowWidth: window.innerWidth };

    if (this.state.isMobile !== isMobile) {
      stateObj = {
        ...stateObj,
        ...{
          isMobile: isMobile,
          openSidebar: isMobile ? false : this.state.openSidebar,
        },
      };
    }

    this.setState(stateObj);
  };

  addPath = ({ id, parentId, mainMode, path }) => {
    this.setState({ addedPath: { id, parentId, mainMode, path } });
  };

  onOpenSidebar = (open, cb) => {
    if (open !== this.state.openSidebar) {
      this.setState({ openSidebar: open }, cb);
    } else if (cb) {
      cb();
    }
  };

  setOpenContact = (contactInfo) => {
    this.setState({ contactInfo: contactInfo });
  };

  handleOpenNewProjectDialog = () => this.setState({ openNewProjectDialog: true });
  handleCloseNewProjectDialog = () => this.setState({ openNewProjectDialog: false });

  onModalContentChange = (modalContent, isImage, open) => {
    modalContent = { content: modalContent, isImage: isImage, open: open };
    this.setState({ modalContent: modalContent });
  };

  checkNavbar = (override = false) => {
    let hideNavbar =
      override && override.value && (override.value === true || override.value === false)
        ? override.value
        : new URLSearchParams(window.location.search).get('hideNavbar') === 'true' ||
          new URLSearchParams(window.location.search).get('hideNavBar') === 'true';
    if (hideNavbar === true || hideNavbar === false) {
      if (hideNavbar !== this.state.hideNavbar) {
        this.setState({ hideNavbar: hideNavbar });
      }
    }
  };

  redirect = (url) => {
    if (url !== this.state.redirect) {
      this.setState({ redirect: url, openSidebar: false });
    }
    this.closeTourDialog();
  };

  setTourDialogInfo = (tourInfo) => {
    this.setState({ tourDialogInfo: tourInfo });
  };

  closeTourDialog = (e, cb) => {
    this.setState({ tourDialogInfo: null }, cb);
  };

  setInvite = (bool) => {
    this.setState({ invite: bool });
  };

  setNewCaptureKey = (e) => {
    this.setState({ newCaptureKey: { key: this.state.newCaptureKey.key + 1, e: e } });
  };

  render() {
    if (!theme) return null;

    let hideNavbar =
      this.state.hideNavbar &&
      (window.location.pathname?.includes(AppUtility.mainModeRoutes[AppUtility.mainModes.viewer].route) ||
        window.location.pathname?.includes(AppUtility.mainModeRoutes[AppUtility.mainModes.thumbnail].route));

    let mainContentClass = 'main-content';

    if (hideNavbar) {
      mainContentClass += ' hideNav';
    }

    let commonProps = {
      isMobile: this.state.isMobile,
      isiOS: this.state.isiOS,
      setNewCaptureKey: this.setNewCaptureKey,
      viewerKey: this.state.viewerKey,
      driveContentKey: this.state.driveContentKey,
      setDriveContentKey: () => {
        this.setState({ driveContentKey: this.state.driveContentKey ? this.state.driveContentKey + 1 : 1 });
      },
      setViewerKey: () => {
        this.setState((oldState) => ({ viewerKey: oldState.viewerKey + 1 }));
      },
      windowWidth: this.state.windowWidth,
      newCaptureKey: this.state.newCaptureKey,
      hubspotUrlInfo: this.state.hubspotUrlInfo,
      setTourFunctions: this.setTourFunctions,
      tourFunctions: this.state.tourFunctions,
      isiOSSafari: this.state.isiOSSafari,
      touch: this.state.touch,
      addPath: this.addPath,
      addedPath: this.state.addedPath,
      setOpenContact: this.setOpenContact,
      setInvite: this.setInvite,
      mainMode: this.state.mainMode,
      [AppUtility.subModeKeys.balance]: this.state[AppUtility.subModeKeys.balance],
      [AppUtility.subModeKeys.overview]: this.state[AppUtility.subModeKeys.overview],
      options: this.state.options,
      openSidebar: this.state.openSidebar,
      onOpenSidebar: this.onOpenSidebar,
      onOpenSnackbar: this.onOpenSnackbar,
      error: this.state.error,
      shareView: this.state.shareView,
      setHideNavbar: this.setHideNavbar,
      openNewProjectDialog: this.state.openNewProjectDialog,
      hideNavbar: this.state.hideNavbar,
      redirect: this.redirect,
      setTourOpen: this.setTourOpen,
      tourOpen: this.state.tourOpen,
      handleOpenNewProjectDialog: this.handleOpenNewProjectDialog,
      onModalContentChange: this.onModalContentChange,
      handleCloseNewProjectDialog: this.handleCloseNewProjectDialog,
    };

    return (
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ConsentProvider>
            <Router>
              <GlobalErrorBoundary>
                <MainContext.Provider value={commonProps}>
                  <AllContexts>
                    <NavigationHelper
                      checkNavbar={this.checkNavbar}
                      redirect={this.redirect}
                      onOpenSidebar={this.onOpenSidebar}
                      tourOpen={this.state.tourOpen}
                      isMobile={this.state.isMobile}
                    >
                      <Helmet>
                        <title>{ApiManager?.displayName ?? 'Ellipsis Drive'}</title>
                        {ApiManager.cloud !== CLOUDS.ellipsis && (
                          <link
                            rel="shortcut icon"
                            href={`/favicons/${
                              ApiManager.cloud === CLOUDS.ellipsis ? 'favicon' : ApiManager?.cloud
                            }.ico?v=${ApiManager?.cloud}`}
                          />
                        )}
                      </Helmet>

                      <NavBar
                        hide={hideNavbar}
                        viewerButtonRef={this.viewerButtonRef}
                        setInvite={this.setInvite}
                        handleOpenNewProjectDialog={this.handleOpenNewProjectDialog}
                        addPath={this.addPath}
                        closeTourDialog={this.closeTourDialog}
                        setTourDialogInfo={this.setTourDialogInfo}
                      />
                      {this.state.tourDialogInfo && (
                        <TourDialog
                          onClose={this.closeTourDialog}
                          {...this.state.tourDialogInfo}
                          setTourOpen={this.setTourOpen}
                        />
                      )}

                      {this.state.contactInfo ? <Contact {...this.state.contactInfo} /> : null}

                      {this.state.invite && <ReferralInvite />}
                      <UploadDialog estimatedTime={7}>
                        <UploadHeader />
                        <UploadContent>
                          <UploadItems />
                        </UploadContent>
                      </UploadDialog>
                      <main>
                        <Demo key="demo" />
                        <Sidebar
                          className={'stepOne'}
                          openSidebar={this.state.openSidebar}
                          onOpenSidebar={this.onOpenSidebar}
                          handleOpenNewProjectDialog={this.handleOpenNewProjectDialog}
                          hideNavbar={hideNavbar}
                          setNewCaptureKey={this.setNewCaptureKey}
                        />
                        <NewProjectDialog />
                        <Box
                          className={mainContentClass}
                          sx={{
                            overflowY: window.location.pathname?.includes(
                              AppUtility.mainModeRoutes[AppUtility.mainModes.viewer].route
                            )
                              ? 'hidden!important'
                              : 'auto',
                          }}
                        >
                          <SuspenseContainer>
                            <Switch>
                              {this.state.redirect ? (
                                <Redirect push key={this.state.redirect} to={this.state.redirect} exact strict />
                              ) : null}
                              {/* <SentryRoute path={AppUtility.mainModeRoutes[AppUtility.mainModes.developer].route}>
                          <Redirect to={AppUtility.mainModeRoutes[AppUtility.mainModes.plugins].route} />
                        </SentryRoute> */}
                              <Route
                                path="/overview"
                                component={() => {
                                  window.location.href =
                                    'https://www.youtube.com/watch?v=dQw4w9WgXcQ&autoplay=1&mute=1';
                                  return null;
                                }}
                              />
                              <SentryRoute exact path={['/plugins']}>
                                <Redirect to="/integrate" />
                              </SentryRoute>
                              <SentryRoute
                                path={[
                                  '/drive/projects',
                                  AppUtility.mainModeRoutes[AppUtility.mainModes.drive].route,
                                  '/drive/me',
                                  AppUtility.mainModeRoutes[AppUtility.mainModes.viewer].route,
                                  AppUtility.mainModeRoutes[AppUtility.mainModes.mapManagement].route,
                                ]}
                                render={() => <Drive viewerButtonRef={this.viewerButtonRef} />}
                              />
                              <SentryRoute
                                path={AppUtility.mainModeRoutes[AppUtility.mainModes.thumbnail].route}
                                component={ThumbnailPage}
                              />
                              <SentryRoute exact path={'/login'} render={() => <LoginPage />} />
                              <SentryRoute
                                exact
                                path={'/invoice'}
                                render={() => (
                                  <Invoice {...commonProps} mode={this.state[AppUtility.subModeKeys.balance]} />
                                )}
                              />
                              <SentryRoute exact path={'/update-credentials'} render={() => <UpdateCredentials />} />
                              <SentryRoute exact path={'/forgot-password'} render={() => <ForgotPasswordPage />} />
                              <SentryRoute exact path={'/register'} render={() => <RegisterPage />} />
                              <SentryRoute exact path={'/verify'} render={() => <VerificationNeededPage />} />
                              <SentryRoute exact path={'/notification'} render={() => <RegistrationPage />} />
                              <SentryRoute exact path={'/oauth/consent'} render={() => <OAuthConsent />} />
                              <SentryRoute path={'/account-settings'} render={() => <AccountManagement />} />
                              <SentryRoute
                                path={AppUtility.mainModeRoutes[AppUtility.mainModes.storage].route}
                                component={Storage}
                              />
                              <SentryRoute
                                path={[
                                  AppUtility.mainModeRoutes[AppUtility.mainModes.balance].route,
                                  AppUtility.mainModeRoutes[AppUtility.mainModes.plan].route,
                                ]}
                                render={() => (
                                  <Redirect to={AppUtility.mainModeRoutes[AppUtility.mainModes.storage].route} />
                                )}
                              />
                              <SentryRoute
                                path={AppUtility.mainModeRoutes[AppUtility.mainModes.pricing].route}
                                render={() => window.location.replace('https://ellipsis-drive.com/pricing/')}
                              />
                              <SentryRoute
                                path={AppUtility.mainModeRoutes[AppUtility.mainModes.backoffice].route}
                                render={() => <Backoffice {...commonProps} />}
                              />
                              <SentryRoute
                                path={AppUtility.mainModeRoutes[AppUtility.mainModes.plugins].route}
                                render={() => <Plugins {...commonProps} />}
                              />

                              <SentryRoute
                                path={AppUtility.mainModeRoutes[AppUtility.mainModes.services].route}
                                render={() => <Services {...commonProps} />}
                              />
                              <SentryRoute
                                path={AppUtility.mainModeRoutes[AppUtility.mainModes.search].route}
                                render={() => <Search />}
                              />
                              <SentryRoute
                                path={AppUtility.mainModeRoutes[AppUtility.mainModes.disclosure].route}
                                render={() => <Disclosure />}
                              />

                              <SentryRoute render={() => <Page404 />} />
                            </Switch>
                          </SuspenseContainer>
                        </Box>
                      </main>
                    </NavigationHelper>

                    <SnackbarControl opts={this.state.snackbarOpts} />
                    {this.state.modalContent.open ? (
                      <ModalView
                        onModalChange={this.onModalContentChange}
                        modalContent={this.state.modalContent}
                        map={this.state.selectedMap}
                        onOpenSnackbar={this.onOpenSnackbar}
                      />
                    ) : null}
                    <UserInfoDialog />
                    <CookieNotification />
                  </AllContexts>
                </MainContext.Provider>
              </GlobalErrorBoundary>
            </Router>
          </ConsentProvider>
        </ThemeProvider>
      </CacheProvider>
    );
  }
}

export default App;
