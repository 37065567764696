import VersionUtility from 'VersionUtility';

export const highresDem = () => {
  return VersionUtility.convertPathInfo({
    id: '085f5e10-63b6-4e8f-a4c6-dce9689100d3',
    name: '30m Elevation',
    user: { id: '9113af2b-943f-4689-b5f4-e575167d5a38', username: 'admin', disabled: false, plan: { type: 'pro' } },
    type: 'raster',
    driveLocation: {
      root: 'myDrive',
      path: [
        { id: '085f5e10-63b6-4e8f-a4c6-dce9689100d3', name: '30m Elevation' },
        { id: '13094153-ee32-4e96-a1da-288c5caf1b7f', name: 'background maps' },
        { id: '94083ea1-b239-46bc-8d9f-ee4adf570a1c', name: 'maps used elsewhere' },
        { id: '940a48e9-ca65-46d8-9bd2-9f8eca970fc5', name: 'development' },
        { id: 'c21913f1-07a4-4f56-bae0-6c02ee717121', name: 'Ellipsis Drive' },
      ],
    },
    favorite: false,
    yourAccess: { accessLevel: 1000, processingUnits: null, geoFence: {} },
    publicAccess: { accessLevel: 100, processingUnits: 80000000000, hidden: true, canHide: true, geoFence: {} },
    metadata: { description: '', properties: {}, attribution: null, license: '' },
    inheritedPublicAccess: { accessLevel: 100, processingUnits: 80000000000 },
    trashed: false,
    plans: [],
    hashtags: [],
    location: {
      current: {
        id: '371c01b0-ca0d-47de-ac44-0eb7850e231d',
        country: 'Belgium',
        city: 'Saint-Ghislain',
        x: 3.8657456556216,
        y: 50.46889456399997,
      },
    },
    raster: {
      timestamps: [
        {
          id: '3179fa80-60ad-41c7-ae67-cdd5eeeca693',
          date: { from: '2023-03-02T00:00:00.000Z', to: '2023-03-02T00:00:00.000Z' },
          extent: { xMin: -179.00014, xMax: 179.00014, yMin: -56.000137, yMax: 61.000137 },
          description: 'x',
          zoom: 13,
          resolution: 30.715,
          availability: { blocked: false },
          uploads: {},
          interpolationMethod: 'linear',
          totalSize: 308.21812438964844,
          status: 'active',
          trashed: false,
          progress: null,
        },
      ],
      styles: [
        {
          id: 'bbd4cec7-e692-46a2-86f1-f081204d57e4',
          name: 'band 1',
          description: null,
          method: 'bandToColor',
          parameters: {
            bandNumber: 1,
            continuous: true,
            transitionPoints: [
              { color: '#2A5C84', value: -417 },
              { color: '#74AE56', value: 1527 },
              { color: '#FFE733', value: 3471 },
              { color: '#FF8C01', value: 5415 },
              { color: '#ED2938', value: 7359 },
            ],
            alpha: 0.5,
          },
          default: true,
        },
      ],
      bands: [
        { number: 1, name: 'Gray' },
        { number: 2, name: 'transparent' },
      ],
      format: 'int16',
      includesTransparent: false,
      interpolationMethod: 'linear',
    },
    favoriteLocation: {
      path: [
        { id: '085f5e10-63b6-4e8f-a4c6-dce9689100d3', name: '30m Elevation' },
        { id: '13094153-ee32-4e96-a1da-288c5caf1b7f', name: 'background maps' },
        { id: '94083ea1-b239-46bc-8d9f-ee4adf570a1c', name: 'maps used elsewhere' },
        { id: '940a48e9-ca65-46d8-9bd2-9f8eca970fc5', name: 'development' },
        { id: 'c21913f1-07a4-4f56-bae0-6c02ee717121', name: 'Ellipsis Drive' },
      ],
    },
    storageSize: 308.21910095214844,
  });
};

export const standardDem = () => {
  return VersionUtility.convertPathInfo({
    id: '77239b78-ff95-4c30-a90e-0428964a0f00',
    name: 'Global Altitude',
    user: {
      id: '9113af2b-943f-4689-b5f4-e575167d5a38',
      username: 'admin',
      disabled: false,
      plan: { type: 'pro' },
    },
    type: 'raster',
    driveLocation: {
      root: 'myDrive',
      path: [
        { id: '77239b78-ff95-4c30-a90e-0428964a0f00', name: 'Global Altitude' },
        { id: 'cc44cfbf-bf88-4c77-a988-79b414ef90da', name: 'end result' },
        { id: '8176efeb-4efc-4864-afd0-8c795e7ec0e7', name: 'general demo' },
        { id: '9b88f476-3db9-4892-b426-f390f9ccea3e', name: 'needed for demo tour app' },
        { id: '940a48e9-ca65-46d8-9bd2-9f8eca970fc5', name: 'development' },
        { id: 'c21913f1-07a4-4f56-bae0-6c02ee717121', name: 'Ellipsis Drive' },
      ],
    },
    favorite: false,
    yourAccess: { accessLevel: 1000, processingUnits: null, geoFence: {} },
    publicAccess: {
      accessLevel: 200,
      processingUnits: 80000000000,
      hidden: false,
      canHide: true,
      geoFence: {},
    },
    metadata: { description: '', properties: {}, attribution: null, license: '' },
    inheritedPublicAccess: { accessLevel: 100, processingUnits: 80000000000 },
    trashed: false,
    plans: [],
    hashtags: [],
    location: {
      current: {
        id: '371c01b0-ca0d-47de-ac44-0eb7850e231d',
        country: 'Belgium',
        city: 'Saint-Ghislain',
        x: 3.8657456556216,
        y: 50.46889456399997,
      },
    },
    raster: {
      timestamps: [
        {
          id: '83a6860b-3c34-4a53-9d3f-d123019eff7c',
          date: { from: '2021-02-27T00:00:00.000Z', to: '2021-03-06T00:00:00.000Z' },
          extent: { xMin: -180, xMax: 179.97046, yMin: -72.00044, yMax: 84.00042 },
          description: null,
          zoom: 11,
          resolution: 92,
          availability: { blocked: false },
          uploads: {},
          interpolationMethod: 'nearest',
          totalSize: 71.02248001098633,
          status: 'active',
          trashed: false,
          progress: null,
        },
      ],
      styles: [
        {
          id: '2e779c98-1f5b-4024-952f-2c7eb01f8c00',
          name: 'altitude',
          description: null,
          method: 'bandToColor',
          parameters: {
            alpha: 1,
            bandNumber: 1,
            continuous: true,
            transitionPoints: [
              { color: '#2A5C84', value: 0 },
              { color: '#74AE56', value: 100 },
              { color: '#FFE733', value: 500 },
              { color: '#FF8C01', value: 1000 },
              { color: '#ED2938', value: 4000 },
            ],
          },
          default: true,
        },
      ],
      bands: [
        { number: 1, name: 'altitude' },
        { number: 2, name: 'transparent' },
      ],
      format: 'float32',
      includesTransparent: false,
      interpolationMethod: 'nearest',
    },
    favoriteLocation: {
      path: [
        { id: '77239b78-ff95-4c30-a90e-0428964a0f00', name: 'Global Altitude' },
        { id: 'cc44cfbf-bf88-4c77-a988-79b414ef90da', name: 'end result' },
        { id: '8176efeb-4efc-4864-afd0-8c795e7ec0e7', name: 'general demo' },
        { id: '9b88f476-3db9-4892-b426-f390f9ccea3e', name: 'needed for demo tour app' },
        { id: '940a48e9-ca65-46d8-9bd2-9f8eca970fc5', name: 'development' },
        { id: 'c21913f1-07a4-4f56-bae0-6c02ee717121', name: 'Ellipsis Drive' },
      ],
    },
    storageSize: 71.02345657348633,
  });
};
