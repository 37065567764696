import React, { useState, useEffect, useRef } from 'react';

import { Button, Stack, TextField, Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useMainContext } from 'ReusableComponents';
import HiddenHubspotFields from '../RegisterPage/FormSide/HiddenHubspotFields';

import { useInput /* , useDebounce */ } from '../../hooks';
import ApiManager from '../../ApiManager';
import useStyles from './useLoginPageStyles';
import { /* useAuth, */ useAuthFuncs } from '../AuthContext';
import FullScreenLayout from '../FullScreenLayout';
import { LoadingButton } from '../../ReusableComponents';
import PageRedirect from 'ReusableComponents/PageRedirect';
import { useQueryParams } from '../../hooks';
import GoogleIcon from './GoogleIcon';
import MicrosoftIcon from './MicrosoftIcon';
import { CLOUDS } from 'ApiUrls';
import { COOKIE_TYPES, useConsentValue, usePushToDataLayer } from 'ConsentContext';
import { LinkStyleButton } from 'ReusableComponents/LinkStyleButton';

const LoginPage = () => {
  const { classes } = useStyles();
  const history = useHistory();
  // const user = useAuth();
  const { signin } = useAuthFuncs();
  const itemsRef = useRef([]);

  const [username, handleUsernameChange] = useInput();
  // const debouncedUsername = useDebounce(username, 500);

  const { hubspotUrlInfo } = useMainContext();

  const [password, handlePasswordChange] = useInput();
  // const debouncedPassword = useDebounce(password, 500);

  const [redirecting, setRedirecting] = useState(false);
  const [fixedUsername, setFixedUsername] = useState(null);
  const [errors, setErrors] = useState({
    username: '',
    password: '',
    submit: '',
  });

  const { referer, userId, sso } = useQueryParams();

  const [loading, setLoading] = useState(sso);

  const pushToDataLayer = usePushToDataLayer();

  const allParams = useQueryParams();

  const consent = useConsentValue();

  const onlyOnce = useRef(true);
  useEffect(() => {
    if (sso === 'google') {
      if (onlyOnce.current) {
        onlyOnce.current = false;
        const q = new URLSearchParams(allParams).toString();

        ApiManager.get(`/v3/google/callback?${q}`, null, null, null, null, { credentials: 'include' })
          .then((res) => {
            console.log('RES', res);

            try {
              const extraInfo = JSON.parse(localStorage.getItem('ssoHubspotInfo'));
              ApiManager.post(`/v3/internal/setHubspotInfo`, extraInfo, { token: res.token });
            } catch (e) {
              console.log(e);
            }

            handleAfterMath(res.token);
          })
          .catch((e) => {
            console.warn(e);
            setLoading(false);
            setErrors((prev) => ({ ...prev, submit: 'Authentication failed' }));
          });
      }
    } else if (sso === 'saml') {
      if (onlyOnce.current) {
        onlyOnce.current = false;

        console.log('parameters', allParams);

        handleAfterMath(allParams.samlToken);
      }
    }
  });

  const onlyOnce3 = useRef(true);

  useEffect(() => {
    if (onlyOnce3.current && !sso) {
      onlyOnce3.current = false;
      try {
        localStorage.setItem('ssoState', JSON.stringify(history.location.state));
        const hubspotInfo = Object.fromEntries(itemsRef?.current?.map((input) => [input.name, input.value]));

        const extraInfo = {
          username: username.trim(),
          password: password.trim(),
          hubspotInfo: {
            cookie_consent_on_registration: consent,
            ...(consent?.[COOKIE_TYPES?.ANALYTICAL] ? { ...hubspotInfo } : {}),
            submission_url: hubspotUrlInfo,
          },
        };

        localStorage.setItem('ssoHubspotInfo', JSON.stringify(extraInfo));
      } catch (e) {
        console.warn(e);
      }
    }
  }, []);

  const update = history.location?.state?.update;
  const onlyOnce2 = useRef(true);

  useEffect(() => {
    if (userId && onlyOnce2.current) {
      onlyOnce2.current = false;
      ApiManager.get(`/v3/user/${userId}`)
        .then((r) => {
          setFixedUsername(r.username);
        })
        .catch((e) => {
          console.error(e);
          history.push('/404');
        });
    }
  }, [userId]);

  const handleLogin = async (e) => {
    e.preventDefault();
    // remove pathId from the redirect if applicable

    const body = {
      username: userId ? fixedUsername : username.trim().toLowerCase(),
      password: password.trim(),
      validFor: 2678400,
    };
    setLoading(true);

    try {
      const token = await ApiManager.post('/v3/account/login', body);
      pushToDataLayer({
        dataLayer: {
          event: 'log-in-form-send',
        },
      });

      handleAfterMath(token.token);
    } catch (error) {
      setErrors((prev) => ({ ...prev, submit: error.message }));
      setLoading(false);
    }
  };

  const handleAfterMath = (token) => {
    console.log('TOKEN', token);
    let redirectTo;
    let redirectContent;
    if (sso) {
      try {
        redirectTo = JSON.parse(localStorage.getItem('ssoState'))?.from;
        redirectContent = JSON.parse(localStorage.getItem('ssoState'))?.content;
      } catch (e) {
        console.warn(e);
      }
    } else {
      redirectTo = history.location.state?.from;
      redirectContent = history.location.state?.content;
    }

    if (referer) {
      setRedirecting(true);
      setInterval(() => {
        window.location.replace(referer + '?token=' + token);
      }, 3 * 1000);
      return;
    }

    signin(
      { ...{ token: token } },
      () => {
        if (redirectTo && !redirectTo.includes('/register')) {
          history.push(redirectTo, {
            from: '/login',
            password: password,
            content: redirectContent,
          });
        } else {
          history.push('/drive/me');
        }
      },
      history.location?.state?.feature
    );
  };

  if (redirecting) {
    return <PageRedirect message={'You will be redirected to ' + referer} />;
  }

  return (
    <FullScreenLayout>
      {ApiManager.cloud === CLOUDS.ellipsis && <HiddenHubspotFields itemsRef={itemsRef} />}

      <Helmet>
        <title>Log in - {ApiManager?.displayName ?? 'Ellipsis Drive'}</title>
      </Helmet>
      <Typography className={classes.title} component="h1" variant="h3" gutterBottom>
        {update ? 'Enter your password to update your ' + update : `Log in to ${ApiManager.displayName}`}
      </Typography>
      {referer && (
        <div className={classes.warningContainer}>
          <Typography className={classes.warningText}>
            {" By login in you give the webpage '" +
              referer +
              "' full access to your account. \n Please make sure you trust this website."}
          </Typography>
        </div>
      )}
      {errors.submit && (
        <div className={classes.errorContainer}>
          <Typography className={classes.errorText}>{errors.submit}</Typography>
        </div>
      )}
      <form className={classes.contentContainer} onSubmit={handleLogin}>
        <div className={classes.textFieldContainer}>
          {userId ? (
            <>
              <Typography className={classes.textFieldLabel}>Username</Typography>
              <Typography>{fixedUsername}</Typography>
            </>
          ) : (
            <TextField
              label="Username"
              InputLabelProps={{ shrink: true }}
              placeholder="username or email"
              value={username}
              onChange={handleUsernameChange}
              variant="outlined"
              type="text"
              autoComplete="username"
              className={classes.textField}
              helperText={errors.username}
              error={errors.username !== ''}
            />
          )}
        </div>
        <div className={classes.textFieldContainer}>
          <TextField
            label={update && update === 'password' ? 'Old Password' : 'Password'}
            InputLabelProps={{ shrink: true }}
            placeholder="e.g. ••••••••"
            value={password}
            onChange={handlePasswordChange}
            variant="outlined"
            type="password"
            autoComplete="current-password"
            className={classes.textField}
            helperText={errors.password}
            error={errors.password !== ''}
          />
        </div>

        <Stack direction="column" useFlexGap spacing={1} sx={{ mt: 1.5 }}>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            className={classes.loginButton}
            type="submit"
          >
            {update ? 'submit' : 'Log in'}
          </LoadingButton>

          {!update && !loading && ApiManager.idProvider === 'google' && (
            <Typography
              component={'form'}
              action={`${ApiManager.apiUrl}/v3/google/login`}
              method="GET"
              className={classes.googleForm}
            >
              <Button
                className={classes.googleButton}
                variant="outlined"
                startIcon={<GoogleIcon />}
                type="submit"
                color="inherit"
                fullWidth
              >
                <span className={classes.label}>Sign in with Google</span>
              </Button>
            </Typography>
          )}

          {!update && !loading && ApiManager.idProvider === 'saml' && (
            <form action={`${ApiManager.apiUrl}/v3/saml/login`} method="POST" className={classes.googleForm}>
              <Button
                className={classes.googleButton}
                variant="outlined"
                startIcon={<MicrosoftIcon />}
                type="submit"
                color="inherit"
                fullWidth
              >
                <span className={classes.label}>Sign in with Microsoft</span>
              </Button>
            </form>
          )}

          <LinkStyleButton component={Link} to="/forgot-password" sx={{ mt: 1 }}>
            Forgot password
          </LinkStyleButton>
        </Stack>

        {!userId && (
          <div className={classes.signUpContainer}>
            <Typography className={classes.signUpText}>Don&apos;t have an account?</Typography>
            <LinkStyleButton component={Link} to="/register">
              Sign Up
            </LinkStyleButton>
          </div>
        )}
      </form>
    </FullScreenLayout>
  );
};

export default LoginPage;
