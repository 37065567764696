import { SvgIcon } from '@mui/material';
import React from 'react';

const PATHS = (
  <>
    <rect x="1" y="1" width="9" height="9" fill="#f25022" />
    <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
    <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
    <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
  </>
);

const MicrosoftIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 21 21">
    {PATHS}
  </SvgIcon>
);

export default MicrosoftIcon;
