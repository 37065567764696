import React, { useMemo } from 'react';

import { Box } from '@mui/system';
import { List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography, Zoom } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowRightAlt } from '@mui/icons-material';
import ProjectTypeIcon from 'ReusableComponents/ProjectTypeIcon';
import { isValidMap } from 'ReusableComponents/ValidLayer/ValidLayer';
import PatternIcon, {
  GET_GRADIENT,
} from 'ReusableComponents/Visualisations/LayerStyling/AdvancedLayerSettings/StylingMethods/PatternIcon/PatternIcon';
import ApiManager from 'ApiManager';

const SIZE = 24;

const RAINBOW = [
  { startColor: '#F00', endColor: '#ff9a00' },
  { startColor: '#ff9a00', endColor: '#d0de21' },
  { startColor: '#d0de21', endColor: '#4fdc4a' },
  { startColor: '#4fdc4a', endColor: '#2fc9e2' },
  { startColor: '#2fc9e2', endColor: '#1c7fee' },
  { startColor: '#1c7fee', endColor: '#5f15f2' },
  { startColor: '#5f15f2', endColor: '#ba0cf8' },
  { startColor: '#ba0cf8', endColor: '#fb07d9' },
  { startColor: '#fb07d9', endColor: '#F00' },
];

const HILL_SHADE = { startColor: '#000', endColor: '#FFF' };

export const useBlocks = (props) => {
  const { bands, style } = { ...props };

  const memoedBlocks = useMemo(() => {
    const blocks = [];
    var tooltip = null;

    if (style?.method === 'fromColorProperty' || style?.method === 'rgb') {
      tooltip = style?.method === 'fromColorProperty' && 'Uses the color property of the feature';
      blocks.push({ img: '/images/viewer/world.jpg' });
    } else if (style?.method === 'random') {
      tooltip = `Assigns a random color based on ${style?.parameters?.property}`;
      blocks.push(...RAINBOW);
    } else if (style?.method === 'singleColor') {
      tooltip = 'Assigns a single color to all features';
      blocks.push({
        startColor: style?.parameters?.color,
        endColor: style?.parameters?.color,
        icon: style?.parameters?.icon,
      });
    } else if (style?.method === 'rules') {
      blocks.push(
        ...(style?.parameters?.rules?.map((rule) => ({
          startColor: rule?.color,
          endColor: rule?.color,
          pattern: rule?.pattern,
          icon: rule?.icon,
          label: `${rule?.property} ${rule?.operator} ${rule?.value}`,
        })) ?? [])
      );
    } else if (
      style?.method === 'transitionPoints' ||
      style?.method === 'bandToColor' ||
      style?.method === 'classToColor' ||
      style?.method === 'formula' ||
      style?.method === 'index' ||
      style?.method === 'intensity'
    ) {
      const name = style.parameters.transitionPoints ? 'transitionPoints' : 'colorMapping';
      const divisionFactor = style?.parameters?.incrementValue ?? null;

      const indexValue =
        style?.method === 'index' &&
        bands.reduce(
          (acc, band) => ({
            ...acc,
            ...(band?.number === style?.parameters?.positiveBand?.bandNumber && { positive: band }),
            ...(band?.number === style?.parameters?.negativeBand?.bandNumber && { negative: band }),
          }),
          {}
        );

      const title =
        style?.method === 'transitionPoints'
          ? style?.parameters?.property
          : style?.method === 'formula' && style?.parameters.properties
          ? style?.parameters?.formula.replaceAll(
              /(?:property{1})(\d+)/g,
              (_, token) => style?.parameters.properties[Number(token) - 1]
            )
          : style?.method === 'formula'
          ? style?.parameters?.formula.replaceAll(
              /(?:band{1})(\d+)/g,
              (_, token) => bands?.find((band) => band?.number === Number(token))?.name
            )
          : style?.method === 'index'
          ? `(${indexValue?.positive?.name} - ${indexValue?.negative?.name})/(${indexValue?.positive?.name} + ${indexValue?.negative?.name})`
          : style?.method === 'intensity'
          ? `√(${style?.parameters?.bands
              ?.map((intensityBand) => bands?.find((band) => band?.number === intensityBand?.bandNumber)?.name)
              .join('² + ')}²)`
          : (style?.method === 'bandToColor' || style?.method === 'classToColor') && !style?.parameters.property
          ? bands?.find((band) => band?.number === style?.parameters?.bandNumber)?.name
          : style?.method === 'classToColor' && style?.parameters.property
          ? style?.parameters.property
          : null;

      blocks.push(
        style?.parameters?.continuous && style?.method !== 'classToColor' && style?.parameters?.[name]?.[0]
          ? {
              startColor: style.parameters[name][0]?.color,
              endColor: style.parameters[name][0]?.color,
              pattern: style.parameters[name][0]?.pattern,
              icon: style.parameters[name][0]?.icon,
              label: `${title}: lower <> ${
                divisionFactor ? style?.parameters[name][0]?.value * divisionFactor : style?.parameters[name][0]?.value
              }`,
            }
          : null,

        ...(style?.parameters?.[name]?.map((rule, i) => {
          let label;
          if (style?.method === 'classToColor') {
            label = `${rule?.label ?? title}: ${divisionFactor ? rule?.value * divisionFactor : rule?.value}`;
          } else {
            label = `${rule?.label ?? title}: ${
              !style?.parameters?.continuous && style?.method !== 'classToColor' && i === 0 ? 'lower' : rule?.value
            } <> ${style?.parameters?.[name][i + 1]?.value ?? 'higher'}`;
          }

          return {
            startColor: rule?.color,
            endColor:
              (style?.parameters?.continuous && style?.method !== 'classToColor'
                ? style?.parameters?.[name][i + 1]?.color
                : undefined) ?? rule?.color,
            pattern: rule?.pattern,
            icon: rule?.icon,
            label: label,
          };
        }) ?? [])
      );
    } else if (style?.method === 'hillShade') {
      tooltip = `${bands?.find((band) => band?.number === style?.parameters?.bandNumber)?.name}: ${
        style?.parameters?.angle
      }°`;
      blocks.push(HILL_SHADE);
    } else if (style?.method === 'vectorField') {
      blocks.push(...new Array(16).fill(null).map(() => ({ arrow: true })));
    }

    const filteredBlocks = blocks?.filter((b) => !!b?.label);

    return { blocks, tooltip, filteredBlocks };
  }, [style?.method, style?.parameters, bands]);

  return memoedBlocks;
};

const StylingVisualisation = ({ type, layer: { bands }, style }) => {
  return (
    <Visualisation
      defaultColor={
        (style?.method === 'transitionPoints' || style?.method === 'rules') && style.parameters.defaultColor
      }
      type={type}
      bands={bands}
      style={style}
    />
  );
};

export default StylingVisualisation;

const useStyles = makeStyles({ name: 'Visualisation' })((theme, { isPresentationMode, height }) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1),
    height,
    maxHeight: isPresentationMode ? '33vh' : undefined,
    overflow: isPresentationMode ? 'auto' : undefined,
    overscrollBehavior: isPresentationMode ? 'auto' : undefined,
  },
  defaultColor: {
    width: height,
    flexShrink: 0,
    borderRadius: height,
    overflow: 'hidden',
  },
  lineItem: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexGrow: 1,
    height: '100%',
    borderRadius: height,
    overflow: 'hidden',
    '&>*': { flex: '1 1 0px' },
  },
  block: {
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': { transform: 'rotate(-45deg)', height, width: height },
    //background: 'white',
  },
}));

const Visualisation = ({ type, defaultColor, style, bands }) => {
  const { blocks, tooltip, filteredBlocks } = useBlocks({ bands, style });

  const hasIcon = new Set((blocks ?? [])?.map((block) => !!block?.icon)).has(true);

  const { classes: styles } = useStyles({
    isPresentationMode: type === 'presentationMode',
    height: type === 'presentationMode' ? 'auto' : hasIcon ? 24 : 8,
  });

  const preppedBlock = useMemo(
    () =>
      blocks.map((block, i) => {
        if (!!block) {
          const sx = {
            opacity: (style?.parameters?.alpha + 0.25) / 1.25 ?? 1,
            overflow: 'hidden',
            ...(!block?.arrow
              ? {
                  background:
                    block?.icon || block?.pattern
                      ? undefined
                      : block?.img
                      ? `url(${process.env.PUBLIC_URL}${block.img})`
                      : `linear-gradient(90deg, ${block?.startColor} 0%, ${block?.endColor} 100%)`,
                }
              : {}),
            ...(type === 'presentationMode'
              ? {
                  maxHeight: 24,
                  width: 24,
                  borderRadius: 1,
                  height: 24,
                }
              : {}),
          };

          const inner = (
            <Box key={'Box_' + i} className={styles.block} sx={sx}>
              {block?.arrow && <ArrowRightAlt color="error" />}
              {(block?.icon || block?.pattern) && (
                <PatternIcon
                  {...block?.pattern}
                  icon={block.icon}
                  color={block?.startColor}
                  borderRadius={0}
                  width={Math.max(1, block?.pattern?.width / 10)}
                  sx={{ width: '100%', height: '100%', objectFit: block?.icon ? 'contain' : 'cover' }}
                />
              )}
            </Box>
          );

          return type === 'presentationMode' && filteredBlocks?.length > 0 ? (
            <ListItem sx={{ alignItems: 'stretch' }} key={'ListItem' + i}>
              <ListItemIcon sx={{ alignItems: 'center' }}>{inner}</ListItemIcon>
              {!!block?.label && <ListItemText primary={block?.label} sx={{ overflowWrap: 'anywhere' }} />}
            </ListItem>
          ) : block?.label ? (
            <Tooltip key={'Tooltip_' + i} title={block?.label} TransitionComponent={Zoom}>
              <span>{inner}</span>
            </Tooltip>
          ) : (
            inner
          );
        }

        return null;
      }),
    [blocks, filteredBlocks?.length, style?.parameters?.alpha, styles.block, type]
  );

  const inner =
    type === 'presentationMode' ? (
      <List disablePadding dense>
        {filteredBlocks?.length > 0 ? (
          preppedBlock
        ) : (
          <ListItem sx={{ alignItems: 'stretch' }} key="listItem-noBlocks">
            <ListItemIcon sx={{ alignItems: 'center' }}>
              <Box sx={{ display: 'flex', height: 24, width: 24, borderRadius: 6, overflow: 'hidden' }}>
                {preppedBlock}
              </Box>
            </ListItemIcon>
            <ListItemText primary={tooltip} sx={{ overflowWrap: 'anywhere' }} />
          </ListItem>
        )}
      </List>
    ) : (
      <div className={styles.lineItem}>{preppedBlock}</div>
    );

  return (
    <div className={styles.root}>
      {!!tooltip && type !== 'presentationMode' ? (
        <Tooltip title={tooltip} TransitionComponent={Zoom}>
          {inner}
        </Tooltip>
      ) : (
        inner
      )}
      {defaultColor && (
        <Tooltip title="Default Fill" TransitionComponent={Zoom}>
          <Box className={styles.defaultColor} sx={{ background: defaultColor }} />
        </Tooltip>
      )}
    </div>
  );
};

const useListItemTextStyles = makeStyles({
  name: 'StylingListItemText',
})((theme, _params, classes) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.spacing(1),
    [`&.${classes.disabled}`]: { opacity: 0.5, lineHeight: '100%' },
    margin: 0,
  },
  primary: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    [`& .${classes.name}`]: {
      textTransform: 'initial',
      lineHeight: '100%',
      overflow: 'clip',
      overflowClipMargin: 4,
      minWidth: 150,
    },
    [`& .${classes.disabled}`]: { flexGrow: 1 },
  },
  name: {},
  primaryText: {},
  disabled: {},
  changed: { lineHeight: '100%' },
  style: { marginBottom: theme.spacing(0.5) },
}));

export const StylingListItemText = ({
  type = 'styling',
  style,
  disabled = false,
  hideName = false,
  layer,
  asList = true,
  primaryTypographyProps = {},
  secondaryTypographyProps = {},
}) => {
  const { classes: styles, cx } = useListItemTextStyles();
  const { className: primaryTypographyClassName, propsPrimaryTypography } = { ...primaryTypographyProps };
  // const { className: secondaryTypographyClassName, propsSecondaryTypography } = { ...secondaryTypographyProps };

  if (!layer) return null;

  const name =
    type === 'legend' && isValidMap(layer).available
      ? layer.name
      : type === 'legend'
      ? layer.name + ' (' + isValidMap(layer).reason + ')'
      : style.name;

  const primary =
    type === 'legend' || !hideName || (disabled && !hideName) ? (
      <div className={styles.primary}>
        {type === 'legend' && (
          <ProjectTypeIcon style={{ height: 18, width: 18 }} type={layer?.type} base={layer?.base} />
        )}
        {!hideName && (
          <Typography
            className={cx(styles.name, primaryTypographyClassName)}
            variant="button"
            noWrap
            {...propsPrimaryTypography}
          >
            {name}
          </Typography>
        )}
        {disabled && !hideName && (
          <Typography
            className={cx(styles.disabled, primaryTypographyClassName)}
            variant="caption"
            align="right"
            {...propsPrimaryTypography}
          >
            (Deselected)
          </Typography>
        )}
      </div>
    ) : undefined;

  const secondary = style ? (
    <StylingVisualisation style={style} layer={layer} type={'styling'} />
  ) : layer.attribution ? (
    <Typography variant="body2" color="text.secondary" {...secondaryTypographyProps}>
      {layer.attribution}
    </Typography>
  ) : undefined;

  return asList ? (
    <ListItemText
      className={cx(styles.root, disabled && styles.disabled, style && styles.style)}
      disableTypography
      primary={primary}
      secondary={secondary}
    />
  ) : (
    <>
      {primary}
      {secondary}
    </>
  );
};
