import React, { useState, useEffect, useRef } from 'react';

import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { useQueryParams, useAuthFuncs, useInput, useDebounce } from 'hooks';
import { checkUsername, checkEmail, checkPassword } from '../../utils';

import ApiManager from 'ApiManager';
import { LoadingButton, useMainContext } from 'ReusableComponents';
import useStyles from './useFormSideStyles';
import Container from './Container';
import HiddenHubspotFields from './HiddenHubspotFields';
import FirstFormTab from './FirstFormTab';

import { COOKIE_TYPES, useConsentValue, usePushToDataLayer } from 'ConsentContext';
import { CLOUDS } from 'ApiUrls';

const FormSide = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const { signin } = useAuthFuncs();

  const { validationToken, userId, invitedByUserId } = useQueryParams();
  const { hubspotUrlInfo } = useMainContext();
  const [username, handleUsernameChange] = useInput();
  const debouncedUsername = useDebounce(username, 500);
  const [email, handleEmailChange] = useInput();

  const [password, handlePasswordChange] = useInput();
  const [termsChecked, setTermsChecked] = useState(false);

  const [loading, setLoading] = useState(false);

  const consent = useConsentValue();
  const pushToDataLayer = usePushToDataLayer();

  const [errors, setErrors] = useState({
    username: '',
    email: '',
    password: '',
    terms: false,
    submit: '',
  });

  const itemsRef = useRef([]);

  const handleTermsChange = (event) => {
    if (event.target.checked) setErrors((prev) => ({ ...prev, terms: false }));

    setTermsChecked(event.target.checked);
  };

  useEffect(() => {
    if (debouncedUsername !== '') checkUsername(debouncedUsername, setErrors);
  }, [debouncedUsername]);

  useEffect(() => {
    if (email !== '') checkEmail(email, setErrors);
  }, [email]);

  useEffect(() => {
    if (password !== '') checkPassword(password, setErrors);
  }, [password]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (ApiManager.cloud === 'ellipsis') {
      const validUsername = await checkUsername(username.trim(), setErrors);
      const validEmail = validationToken || checkEmail(email.trim(), setErrors);
      const validPassword = checkPassword(password.trim(), setErrors);
      console.log(validUsername);
      if (!validUsername || !validEmail || !validPassword || !termsChecked) {
        return;
      }
    }

    const hubspotInfo = Object.fromEntries(itemsRef?.current?.map((input) => [input.name, input.value]));

    let body = {
      username: username.trim(),
      password: password.trim(),
      hubspotInfo: {
        cookie_consent_on_registration: consent,
        ...(consent?.[COOKIE_TYPES?.ANALYTICAL] ? { ...hubspotInfo } : {}),
        submission_url: hubspotUrlInfo,
      },
    };

    if (validationToken) {
      body.validationToken = validationToken;
      body.userId = userId;
    } else {
      body.email = email.trim();
    }

    if (invitedByUserId) {
      body.invitedByUserId = invitedByUserId;
    }

    setLoading(true);
    try {
      await ApiManager.post('/v3/account', body);
      pushToDataLayer({
        dataLayer: {
          event: 'register-form-send',
        },
      });

      setErrors({
        username: '',
        password: '',
        submit: '',
      });
      if (validationToken) {
        try {
          let token = await ApiManager.post(
            '/v3/account/login',
            { username: body.username, password: body.password },
            null
          );

          signin(token, () => {
            if (invitedByUserId) {
              history.push('/drive/me');
            } else {
              history.push('/drive/me?notification=true');
            }
          });
        } catch {
          history.push('/drive/me');
        }
      } else {
        history.push('/verify');
      }
    } catch (error) {
      setErrors((prev) => ({ ...prev, submit: error.message }));
    }
    setLoading(false);
  };

  return (
    <Container>
      <Typography className={classes.title} variant="h3">
        {`Let's get you set up`}
      </Typography>

      {errors.submit && (
        <div className={classes.errorContainer}>
          <Typography className={classes.errorText}>{errors.submit}</Typography>
        </div>
      )}

      <form onSubmit={onSubmit} className={classes.form}>
        {ApiManager.cloud === CLOUDS.ellipsis && <HiddenHubspotFields itemsRef={itemsRef} />}

        <FirstFormTab
          username={username}
          handleUsernameChange={handleUsernameChange}
          email={email}
          validationToken={validationToken}
          handleEmailChange={handleEmailChange}
          termsChecked={termsChecked}
          handleTermsChange={handleTermsChange}
          password={password}
          handlePasswordChange={handlePasswordChange}
          errors={errors}
        />

        <LoadingButton
          loading={loading}
          disabled={
            !username ||
            (!email && !validationToken) ||
            !password ||
            !termsChecked ||
            errors.username ||
            errors.password ||
            errors.email
          }
          variant="contained"
          color="primary"
          className={classes.submitButton}
          type="submit"
        >
          {'Register'}
        </LoadingButton>
      </form>
    </Container>
  );
};

export default FormSide;
