import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import { COOKIE_TYPES, useConsentSetter, useConsentValue } from 'ConsentContext';
import { makeStyles } from 'tss-react/mui';
import { useHistory } from 'react-router-dom';
import ApiManager from 'ApiManager';
import { CLOUDS } from 'ApiUrls';

const TYPES = [
  {
    type: COOKIE_TYPES.ESSENTIAL,
    default: true,
    disabled: true,
    essential: true,
    title: 'Essential Cookies',
    content:
      'These cookies are required for optimal operation of our website, and cannot be configured. They allow us to offer you the key functions of the website (language used, display resolution, account access etc.), provide you with online advice and secure our website against any attempted fraud.',
  },
  {
    type: COOKIE_TYPES.ANALYTICAL,
    default: true,
    disabled: false,
    title: 'Analytical/Performance Cookies',
    content:
      'These cookies are used to measure and analyze our website audience (e.g. visitor volume, pages viewed, average browsing time). By accepting these cookies, you are helping us improve our website performance.',
  },
  {
    type: COOKIE_TYPES.PERSONALIZATION,
    default: true,
    disabled: false,
    title: 'Personalization Cookies',
    content:
      'These cookies allow us to provide you with online recommendations of products, services and content that match your expectations and preferences. By accepting these cookies, you are opting for an enriched and personalized experience.',
  },
  {
    type: COOKIE_TYPES.ADVERTISING,
    default: true,
    disabled: false,
    title: 'Advertising and Social Media cookies',
    content:
      'These cookies are used for advertisements displayed on third-party websites, including social media, are tailored to your preferences, and help us measure the effectiveness of our advertising campaigns. If you deactivate these cookies, advertising (including ours) will continue to be displayed as you browse the Internet, although it will not be specific to your personal interests or as relevant.',
  },
];

const useStyles = makeStyles({ name: 'CookieNotification' })((theme) => ({
  paper: { borderRadius: theme.spacing(1) },
  container: { padding: theme.spacing(3) },
  title: {},
  button: { textTransform: 'unset' },
  img: { maxWidth: '50%', maxHeight: '25vh', aspectRatio: '7/8', height: '100%', width: 'auto' },
  actions: { padding: 0, gap: theme.spacing(1), marginTop: theme.spacing(2) },
  listItem: { padding: 0 },
  listItemButton: { marginLeft: theme.spacing(-2) },
  listItemIcon: { minWidth: 'unset', marginRight: theme.spacing(2) },
  text: {},
}));

const tagManagerArgs = {
  gtmId: 'GTM-K72V8Z6',
};

const CookieNotification = () => {
  const consent = useConsentValue();
  const setConsent = useConsentSetter();
  const [advanced, setAdvanced] = useState(false);
  const [permissions, setPermissions] = useState(TYPES?.reduce((pv, cv) => ({ ...pv, [cv?.type]: cv?.default }), {}));

  const history = useHistory();

  const { classes } = useStyles();

  const handleSwitch = (e) => {
    setPermissions((old) => ({ ...old, [e?.target?.value]: !old?.[e?.target?.value] }));
  };

  const handleSend = useCallback(
    (type) => {
      if (type === 'all') {
        setConsent(TYPES?.reduce((pv, cv) => ({ ...pv, [cv?.type]: true }), {}));
      } else if (type === 'selected') {
        setConsent(permissions);
      } else {
        setConsent(TYPES?.reduce((pv, cv) => ({ ...pv, [cv?.type]: !!cv?.essential }), {}));
      }
    },
    [permissions, setConsent]
  );

  const mountedRef = useRef(false);
  const initRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    if (mountedRef.current === true && consent?.[COOKIE_TYPES?.ANALYTICAL] && initRef.current !== true) {
      initRef.current = true;

      import('react-gtm-module')
        .then((TagManager) => {
          import('@sentry/react')
            .then((Sentry) => {
              TagManager.initialize(tagManagerArgs);

              if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
                Sentry.init({
                  dsn: 'https://a9467b204ff54395876c190d6a22539f@o882566.ingest.sentry.io/5836558',
                  integrations: [
                    new Sentry.BrowserTracing({
                      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                    }),
                    new Sentry.Replay({ maskAllText: false, blockAllMedia: false }),
                  ],
                  release: process.env.REACT_APP_SENTRY_RELEASE,

                  tracesSampleRate: 0.75,
                  replaysSessionSampleRate: 0.1,
                  replaysOnErrorSampleRate: 1.0,
                });
              }
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    }

    return () => {
      mountedRef.current = false;
    };
  }, [consent, consent?.[COOKIE_TYPES.ANALYTICAL], history]);

  useEffect(() => {
    let isEmbedded;
    try {
      isEmbedded = window.location?.href !== window.parent.location?.href;
    } catch {
      isEmbedded = true;
    }

    if (ApiManager.cloud !== CLOUDS.ellipsis || isEmbedded) {
      handleSend('essential');
    }
  }, [handleSend]);

  let isEmbedded;
  try {
    isEmbedded = window.location?.href !== window.parent.location?.href;
  } catch {
    isEmbedded = true;
  }

  return (
    <Dialog
      id="Cookie"
      open={ApiManager.cloud === CLOUDS.ellipsis && consent?.unset === true && !isEmbedded}
      PaperProps={{ className: classes.paper }}
      sx={{ zIndex: 99999 }}
    >
      {!advanced ? (
        <>
          <DialogContent className={classes.container}>
            <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }} alignItems="center">
              <img src="/images/astronautCookie.webp" alt="Cookie Astronaut" className={classes.img} />
              <div style={{ maxWidth: '100%' }}>
                <Typography className={classes.title} conponent="h1" variant="h3" gutterBottom>
                  We value your privacy
                </Typography>
                <DialogContentText className={classes.text}>
                  We respect your privacy and are committed to protecting your personal information. This cookie policy
                  explains how we use cookies on our website. By using our website, you consent to the use of cookies as
                  described in this policy.
                </DialogContentText>
              </div>
            </Stack>
            <DialogActions className={classes.actions}>
              <Button className={classes.button} variant="outlined" onClick={() => setAdvanced(true)}>
                Customize
              </Button>
              <Button className={classes.button} variant="contained" onClick={() => handleSend('all')}>
                Accept All
              </Button>
            </DialogActions>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogContent className={classes.container}>
            <Typography className={classes.title} conponent="h1" variant="h2">
              Cookies we use
            </Typography>
            <List disablePadding>
              {TYPES?.map((t) => (
                <Fragment key={'fragment_' + t?.type}>
                  <ListItem key={'listItem_' + t?.type} className={classes.listItem}>
                    <ListItemButton
                      className={classes.listItemButton}
                      onClick={() => setAdvanced((old) => (old === t?.type ? true : t?.type))}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <KeyboardArrowDown />
                      </ListItemIcon>
                      <ListItemText primary={t?.title} primaryTypographyProps={{ className: classes.text }} />
                    </ListItemButton>
                    <Switch
                      disabled={t?.disabled}
                      checked={permissions?.[t?.type]}
                      value={t?.type}
                      onChange={handleSwitch}
                    />
                  </ListItem>
                  <Collapse in={advanced === t?.type} key={'collapse_' + t?.type}>
                    <ListItem className={classes.listItem}>
                      <ListItemText primary={t?.content} primaryTypographyProps={{ className: classes.text }} />
                    </ListItem>
                  </Collapse>
                </Fragment>
              ))}
            </List>
            <DialogActions className={classes.actions}>
              <Button className={classes.button} variant="outlined" onClick={() => handleSend('selected')}>
                Allow selection
              </Button>
              <Button className={classes.button} variant="outlined" onClick={() => handleSend('essential')}>
                Reject non-essential
              </Button>
              <Button className={classes.button} variant="contained" onClick={() => handleSend('all')}>
                Allow All
              </Button>
            </DialogActions>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

CookieNotification.displayName = 'CookieNotification';

export default CookieNotification;
