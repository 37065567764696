import React, { useState, useEffect, useCallback } from 'react';

import { Typography /* , Button */ } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// import ApiManager from '../../../ApiManager';
import { SectionTitle /* , useMainContext */ } from '../../../ReusableComponents';
import { useMinimalAuth } from '../../AuthContext';
import ApplicationListItem from './ApplicationListItem';

const useStyles = makeStyles()((theme) => ({
  emptyContainer: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  authorizedAppsContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '6px',
    width: '100%',
  },
}));

const ConnectionKeys = () => {
  const { classes } = useStyles();
  const user = useMinimalAuth();
  // const { onOpenSnackbar } = useMainContext();

  const [authorizedApps, setAuthorizedApps] = useState(null);

  const fetchClients = useCallback(async () => {
    const clients = [{ name: 'Google BigQuery testset' }, { name: 'AWS S3' }, { name: 'Github' }];
    setAuthorizedApps(clients);
  }, [user]);

  useEffect(() => {
    fetchClients();
  }, [user, fetchClients]);

  return (
    <>
      <div>
        <SectionTitle first={true}>
          <SectionTitle.SectionTitleText>External connection keys</SectionTitle.SectionTitleText>
        </SectionTitle>
        <Typography variant="body1" paragraph>
          Ellipsis Drive can read data from sources such as S3 buckets or bigQuery tables. When making use of this you
          need to provide access keys here.
        </Typography>
        {authorizedApps?.length > 0 ? (
          <>
            <Typography variant="subtitle1">Ellipsis Drive has the following keys:</Typography>
            <div className={classes.authorizedAppsContainer}>
              {authorizedApps.map((app) => (
                <ApplicationListItem
                  key={app.name}
                  user={{ id: user.id, username: app.name }}
                  handleDelete={() => {}}
                  {...app}
                />
              ))}
              <ApplicationListItem key={'extra'} user={null} handleDelete={() => {}} />
            </div>
          </>
        ) : (
          <div className={classes.emptyContainer}>
            <Typography variant="subtitle1">No authorized OAuth Apps.</Typography>
          </div>
        )}
      </div>
    </>
  );
};

export default ConnectionKeys;
