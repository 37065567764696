import { useRef } from 'react';

const useHandleClickAndDoubleClick = (
  onSingleClick,
  onDoubleClick,
  doubleClickDelay = 500,
  waitForDoubleClick = false
) => {
  const clickCount = useRef(0);
  const lastClickTime = useRef(0);
  const timerRef = useRef(null);

  const handleClick = (e) => {
    // if it's the first click, increment clickCount, set time, and perform action if not `waitForDoubleClick`
    const currentTime = Date.now();
    const clickDelay = currentTime - lastClickTime.current;
    lastClickTime.current = currentTime;
    if (clickCount.current === 0) {
      clickCount.current = 1;

      if (waitForDoubleClick) {
        timerRef.current = setTimeout(() => {
          clickCount.current = 0;
          onSingleClick(e);
        }, doubleClickDelay);
      } else {
        onSingleClick(e);

        timerRef.current = setTimeout(() => {
          clickCount.current = 0;
        }, doubleClickDelay);
      }
    }
    // if it's the second click, perform the double click action
    else if (clickCount.current === 1 && clickDelay < doubleClickDelay) {
      if (timerRef.current) clearTimeout(timerRef.current);

      clickCount.current = 0;
      onDoubleClick(e);
    }
    /* // if it's a late click
    else {
      if (timerRef.current) clearTimeout(timerRef.current);

      clickCount.current = 0;
      onSingleClick(e);
    } */
  };

  return handleClick;
};

export default useHandleClickAndDoubleClick;
