import ApiManager from 'ApiManager';
import { CLOUDS } from 'ApiUrls';

import { highresDem } from 'Viewer/standardDem';

export const tileLayers = (input) => {
  if ((input?.user?.id ?? input) === '20bb4cfb-1839-41dd-bd3e-81b4cf9fae58') {
    return [
      {
        id: 1,
        name: 'OS Base Map',
        base: true,
        type: 'wmts',
        url: 'https://api.os.uk/maps/raster/v1/zxy/Light_3857/{z}/{x}/{y}.png?key=NAXG7ktOvAxMv1Tq4ap8cONwUwFAFuGS',
        zoom: 18,
        idName: 'base',
        attribution: 'OS Base Map',
      },
      {
        id: 2,
        name: 'Base Map',
        base: true,
        type: 'wmts',
        url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 18,
        idName: 'base',
        attribution: 'OpenStreetMap Contributors',
      },
      {
        id: 3,
        name: 'Satellite Layer',
        base: true,
        type: 'wmts',
        url: 'https://www.google.com/maps/vt?lyrs=s@189&x={x}&y={y}&z={z}',
        zoom: 18,
        idName: 'satellite',
        attribution: 'Google Maps',
      },
      {
        id: 4,
        base: true,
        name: 'Hybrid Layer',
        type: 'wmts',
        url: 'https://www.google.com/maps/vt?lyrs=y@189&x={x}&y={y}&z={z}',
        zoom: 18,
        idName: 'hybrid',
        attribution: 'Google Maps',
      },
      {
        id: 5,
        base: true,
        name: 'Topographic Layer',
        type: 'wmts',
        url: ' https://a.tile.opentopomap.org/{z}/{x}/{y}.png',
        zoom: 17,
        idName: 'topographic',
        attribution: 'OpenStreetMap Contributors',
      },
      {
        id: 6,
        name: '3D Buildings',
        base: true,
        type: 'buildings',
        url: 'https://a.data.osmbuildings.org/0.2/ph2apjye/tile/15/{x}/{y}.json',
        zoom: 15,
        idName: 'buildings',
        attribution: 'OpenStreetMap Contributors',
      },
      highresDem(),
    ];
  }

  let res = [
    {
      id: 1,
      name: 'Base Map',
      base: true,
      type: 'wmts',
      url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 18,
      idName: 'base',
      attribution: 'OpenStreetMap Contributors',
    },
    {
      id: 2,
      name: 'Satellite Layer',
      base: true,
      type: 'wmts',
      url: 'https://www.google.com/maps/vt?lyrs=s@189&x={x}&y={y}&z={z}',
      zoom: 18,
      idName: 'satellite',
      attribution: 'Google Maps',
    },
    {
      id: 3,
      base: true,
      name: 'Hybrid Layer',
      type: 'wmts',
      url: 'https://www.google.com/maps/vt?lyrs=y@189&x={x}&y={y}&z={z}',
      zoom: 18,
      idName: 'hybrid',
      attribution: 'Google Maps',
    },
    {
      id: 4,
      base: true,
      name: 'Topographic Layer',
      type: 'wmts',
      url: ' https://a.tile.opentopomap.org/{z}/{x}/{y}.png',
      zoom: 17,
      idName: 'topographic',
      attribution: 'OpenStreetMap Contributors',
    } /*
    {
      id: 5,
      name: '3D Buildings',
      base: true,
      type: 'buildings',
      url: 'https://a.data.osmbuildings.org/0.2/ph2apjye/tile/15/{x}/{y}.json',
      zoom: 15,
      idName: 'buildings',
      attribution: 'OpenStreetMap Contributors',
    },*/,
  ];

  if (ApiManager.cloud === CLOUDS.ellipsis) {
    res.push(highresDem());
  }

  return res;
};
