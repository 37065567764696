import React, { useState, useEffect } from 'react';

import { Typography, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useInput, useDebounce } from '../../hooks';
import ApiManager from '../../ApiManager';
import useStyles from './useForgotPasswordPageStyles';
import { checkEmail } from '../utils';
import FullScreenLayout from '../FullScreenLayout';
import { LoadingButton } from '../../ReusableComponents';
import { useHistory } from 'react-router-dom';
import { usePushToDataLayer } from 'ConsentContext';
import { LinkStyleButton } from 'ReusableComponents/LinkStyleButton';

const Login = () => {
  const { classes } = useStyles();

  const [email, handleEmailChange] = useInput();
  const debouncedEmail = useDebounce(email, 500);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    submit: '',
  });
  const [success /* , setSuccess */] = useState(false);

  const history = useHistory();

  const pushToDataLayer = usePushToDataLayer();

  useEffect(() => {
    if (debouncedEmail !== '') checkEmail(debouncedEmail, setErrors);
  }, [debouncedEmail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validEmail = checkEmail(email.trim(), setErrors);
    if (!validEmail) {
      return;
    }

    const body = {
      email: email.trim(),
    };
    setErrors({ email: '', submit: '' });
    setLoading(true);
    try {
      await ApiManager.post('/v3/account/security/password/reset', body);
      pushToDataLayer({
        dataLayer: {
          event: 'forgot-password-form-send',
        },
      });

      history.push(`/notification?type=resetPasswordRequest`);
    } catch (error) {
      setErrors((prev) => ({ ...prev, submit: error.message }));
    }
    setLoading(false);
  };

  return (
    <FullScreenLayout>
      <Helmet>
        <title>Forgot password - {ApiManager?.displayName ?? 'Ellipsis Drive'}</title>
      </Helmet>
      <Typography className={classes.title} component="h1" variant="h3" gutterBottom>
        Forgot password?
      </Typography>
      <Typography className={classes.subTitle} gutterBottom>
        {`Don't worry! Fill in your email and we'll send you a link to reset your password.`}
      </Typography>

      {errors.submit && (
        <div className={classes.errorContainer}>
          <Typography className={classes.errorText}>{errors.submit}</Typography>
        </div>
      )}

      {success && (
        <div className={classes.successContainer}>
          <Typography className={classes.successText}>
            Thanks! An email was sent to your address with a link to verify that you own this account. You&apos;ll be
            able to reset your password with that link.
          </Typography>
        </div>
      )}

      <form className={classes.contentContainer} onSubmit={handleSubmit}>
        <div className={classes.textFieldContainer}>
          <TextField
            label="Email Address"
            InputLabelProps={{ shrink: true }}
            placeholder="e.g. you@company.com"
            value={email}
            onChange={handleEmailChange}
            variant="outlined"
            type="email"
            className={classes.textField}
            helperText={errors.email}
            error={errors.email !== ''}
          />
        </div>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.sendEmailButton}
          type="submit"
        >
          Send Email
        </LoadingButton>
        <LinkStyleButton component={Link} to="/login">
          Back to Log in
        </LinkStyleButton>
      </form>
    </FullScreenLayout>
  );
};

export default Login;
